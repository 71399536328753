import { AfterViewInit, OnDestroy, OnInit, HostBinding, Directive } from '@angular/core';
import { Subscribable } from './subscribable';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BaseComponent extends Subscribable implements OnInit, OnDestroy, AfterViewInit {
  @HostBinding('style.display') display = 'block';

  public ngOnInit(): void {
    this.onInit();
  }

  public ngAfterViewInit(): void {
    this.onAfterViewInit();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.onDestroy();
  }

  protected onInit(): void {
    // virtual method
  }

  protected onAfterViewInit(): void {
    // virtual method
  }

  protected onDestroy(): void {
    // virtual method
  }
}
