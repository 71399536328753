import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppThemeEnum, CommonConstant, IconName, STORAGE, UserService } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';
import { filter, tap } from 'rxjs';
import { LOTTIE_ANIMATIONS } from 'src/shared/constants/lottie.const';
import { SSOAuthService } from '../shared/services/auth/sso-auth.service';
import { AppApiService } from './core/services/app-api.service';
import { ConsentDialogComponent } from './modules/dialog/components/consent-dialog/consent-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public lottieOptions = LOTTIE_ANIMATIONS.PUBLISHED;

  constructor(
    private _title: Title,
    private _translateService: TranslateService,
    @Inject(STORAGE) private _storage: Storage,
    private readonly domSanitizer: DomSanitizer,
    private readonly matDialog: MatDialog,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly _route: ActivatedRoute,
    private readonly appService: AppApiService,
    private readonly _ssoAuthService: SSOAuthService,
    private readonly userService: UserService,
  ) {
    this._title.setTitle(this._translateService.instant('AppName').toUpperCase());
    this._registerSvgIcon();
    this._initAppAppearance();
  }

  ngOnInit(): void {
    const code = this._route.snapshot.queryParams?.code;
    if (!!code) {
      this._handleSSOSignIn(code);
    }

    this.userService.getUserProfile().pipe(
      filter(user => !!user?._user && !user._user.consentAt),
      tap(() => {
        return
        this.matDialog.open(
          ConsentDialogComponent,
          {
            disableClose: true,
          },
        )
      }),
    ).subscribe()
  }

  private _handleSSOSignIn(code: string) {
    const url = `auth/tokens`;
    if (!!code) {
      this.appService
        .create({ code }, url)
        .pipe()
        .subscribe((res: any) => {
          const token = res?.access_token;
          const refreshToken = res?.refresh_token;
          this._ssoAuthService.setTokens({ token, refreshToken });
          window.close();
        });
    }
  }

  private _initAppAppearance() {
    const selectedTheme = (this._storage.getItem(CommonConstant.THEME_TOKEN) || CommonConstant.DEFAULT_THEME) as AppThemeEnum;
    if (selectedTheme === AppThemeEnum.DARK) {
      document.body.classList.add('dark-theme');
      return;
    }
    document.body.classList.remove('dark-theme');
  }
  private _registerSvgIcon() {
    this.matIconRegistry.addSvgIcon(IconName.Book, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/book.svg'));
    this.matIconRegistry.addSvgIcon(
      IconName.BubbleIcon1,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/game-bubble-eye.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.ChevronDown,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/down-chervon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FilterChervonDown,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/filter-chevron-down.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.Filter,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/filter.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FilterActiveChevronDown,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/filter-active-chevron-down.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.UserProfileChevronDown,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/user-profile-chevron-down.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.Lock,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/lock.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.LogOut,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/logout.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.Import,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/import.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.Export,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/export.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      IconName.ImportGrey,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/import-grey.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.ExportGrey,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/export-grey.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.GlossaryChervonDown,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/glossary-chevron-down.svg'),
    );
    this.matIconRegistry.addSvgIcon(IconName.Back, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/back.svg'));
    this.matIconRegistry.addSvgIcon(IconName.Plus, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/plus.svg'));
    this.matIconRegistry.addSvgIcon(IconName.ToTop, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/to-top.svg'));
    this.matIconRegistry.addSvgIcon(IconName.Pen, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/pen.svg'));
    this.matIconRegistry.addSvgIcon(
      IconName.ChanelLogo,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chanel-logo.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.MainChanelLogo,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/chanel-logo-white.svg'),
    );
    this.matIconRegistry.addSvgIcon(IconName.Back, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/back.svg'));
    this.matIconRegistry.addSvgIcon(
      IconName.RejectedPen,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/rejected-pen.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.UserProfileActiveChevronDown,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/user-profile-active-chervon-down.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.GuideBubbleCheck,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/guide-bubble-check.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.GuideBubbleEdit,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/guide-bubble-edit.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.TextBubbleCopy,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/text-bubble-copy.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.ThumbUp,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/thumb-up.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FloatingBarPen,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/floating-bar-pen.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FloatingBarDisabledPen,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/floating-bar-disabled-pen.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FloatingBarReview,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/floating-bar-review.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FloatingBarDisabledReview,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/floating-bar-disabled-review.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FloatingBarCross,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/floating-bar-cross.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FloatingBarCheck,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/floating-bar-check.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FloatingBarDisabledCheck,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/floating-bar-disabled-check.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.FloatingBarRejectedPen,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/floating-bar-rejected-pen.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.NodataSearch,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/no-data-search.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.SearchInput,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/search.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.DraftStatusDefault,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/draft-status-default.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.DraftStatusClose,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/draft-status-close.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.DraftStatusValidated,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/draft-status-validated.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.DraftStatusRejected,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/draft-status-rejected.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.DraftStatusWip,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/draft-status-wip.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.KeyNotes,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/key-notes.svg'),
    );
    this.matIconRegistry.addSvgIcon(IconName.Link, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/link.svg'));
    this.matIconRegistry.addSvgIcon(IconName.Plus2, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/plus-2.svg'));
    this.matIconRegistry.addSvgIcon(IconName.PlusWhite, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/plus-white.svg'));
    this.matIconRegistry.addSvgIcon(IconName.Clock, this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/clock.svg'));
    this.matIconRegistry.addSvgIcon(
      IconName.SectionMenu,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/menu-sections.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.DownloadCSVArrowDown,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/download-csv-arrow-down.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.Hand,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/hand.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.DownloadCSVArrowDownGrey,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/download-csv-arrow-down-grey.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.ChangeLog,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/change-log.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.Translation,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/translation.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.TranslationError,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/translation-error.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.TranslationSuccess,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/translation-success.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.TranslationGoogle,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/translation-google.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.TranslationGoogleError,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/translation-google-error.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.TranslationGoogleSuccess,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/translation-google-success.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.ArrowRight,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/arrow-right.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.GEAR,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/gear.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.SHUTTLE,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/shuttle.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.PROTOTYPE,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/prototype.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.VOLUME_ON,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/volume-on.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.VOLUME_OFF,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/volume-off.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      IconName.PHONE_ROTATE,
      this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/icons/phone-rotate.svg'),
    );
  }
}
