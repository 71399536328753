import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CustomDateFormatEnum } from '../enums';

@Pipe({
  name: 'customFormatDate',
})
export class CustomFormatDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args: any): any {
    const date = new Date(value);
    let transformedDate;
    switch (args) {
      case CustomDateFormatEnum.dateMonthYear:
        let nthNumber;
        if (date.getDate() > 3 && date.getDate() < 21) {
          nthNumber = 'th';
        }
        switch (date.getDate() % 10) {
          case 1:
            nthNumber = 'st';
            break;
          case 2:
            nthNumber = 'nd';
            break;
          case 3:
            nthNumber = 'rd';
            break;
          default:
            nthNumber = 'th';
        }
        return (transformedDate = super.transform(value, 'd: LLLL yyyy')?.replace(':', nthNumber));
      case CustomDateFormatEnum.dateMonthYearHoursMinute:
        return (transformedDate = super.transform(value, 'dd LLL. yyyy - HH:mm')?.replace(':', 'h'));
    }
  }
}
