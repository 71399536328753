import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyHistoryDialogComponent } from './key-history-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { KeyHistoryItemModule } from '../key-history-item';
import { PipesModule } from '../../pipes';
import { DirectiveModule } from 'src/shared/directives/directive.module';

@NgModule({
  declarations: [KeyHistoryDialogComponent],
  imports: [DirectiveModule, CommonModule, TranslateModule, KeyHistoryItemModule, PipesModule],
  exports: [KeyHistoryDialogComponent],
})
export class KeyHistoryDialogModule {}
