import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGoogleTranslateResponse } from '@app/shared';
import { map, Observable } from 'rxjs';

@Injectable()
export class GoogleTranslateService {
  apiUrl = 'translate';

  constructor(private _httpClient: HttpClient) {}

  public getGoogleTranslateValueByProject(projectId: string, text: string, languageTarget: string): Observable<string> {
    return this._httpClient
      .post<IGoogleTranslateResponse>(`${this.apiUrl}/${projectId}`, { text, languageTarget })
      .pipe(map((response) => response.translation));
  }

  public getGoogleTranslateValue(text: string, languageTarget: string, languageSource: string): Observable<string> {
    return this._httpClient
      .post<IGoogleTranslateResponse>(`${this.apiUrl}`, { text, languageTarget, languageSource })
      .pipe(map((response) => response.translation));
  }
}
