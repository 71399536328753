export enum TranslationStatusEnum {
  Default = 'default',
  InProgress = 'in-progress',
  Success = 'success',
  Error = 'error',
}

export enum MarkTypes {
  bold = 'bold',
  italic = 'italic',
  underline = 'underlined',
}

