import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralNoticeComponent } from './general-notice.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { RoleVisibilityModule } from '../../directives';
import { MatIconModule } from '@angular/material/icon';
import { DirectiveModule } from 'src/shared/directives/directive.module';
import { SlateEditable } from 'slate-angular';
import { TextEditorButtonComponent } from '../text-editor-slate/text-editor-button.component';

@NgModule({
  declarations: [GeneralNoticeComponent],
  imports: [ DirectiveModule, CommonModule, TranslateModule, MatIconModule, FormsModule, RoleVisibilityModule, SlateEditable, TextEditorButtonComponent ],
  exports: [GeneralNoticeComponent],
})
export class GeneralNoticeModule {}
