import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SpinnerType } from '../../enums';
import { LocalSpinnerService } from './local-spinner.service';

@Component({
  selector: 'app-local-spinner',
  templateUrl: './local-spinner.component.html',
  styleUrls: ['./local-spinner.component.scss'],
})
export class LocalSpinnerComponent implements OnInit {
  public readonly SpinnerType = SpinnerType;

  @Input() componentId: string;
  @Input() backgroundType: SpinnerType = SpinnerType.Global;
  @Input() width: string;
  @Input() minWidth: string;
  @Input() height: string;
  @Input() minHeight: string;

  public showSpinner$: Observable<boolean>;

  constructor(private _localSpinnerService: LocalSpinnerService) { }

  ngOnInit(): void {
    if (this.componentId) {
      this.showSpinner$ = this._localSpinnerService.registerComponent(this.componentId);
    }
    if (this.backgroundType === SpinnerType.Button) {
      this.minHeight = this.minHeight || 'auto'
    }
  }
}
