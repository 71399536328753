import { Pipe, PipeTransform } from '@angular/core';
import { DraftKeyStatusEnum, LanguageStatusEnum } from '../enums/language-status.enum';

@Pipe({
  name: 'headerClassNameByDraftStatus',
})
export class HeaderClassNameByDraftStatus implements PipeTransform {
  public transform(data: any, params: any): any {
    // special case for rejected status https://app.clickup.com/t/865bx7bxn
    if (
      (!data?.draftKeyStatus ||
        data?.draftKeyStatus?.status === DraftKeyStatusEnum.EDITED ||
        data?.draftKeyStatus?.status === DraftKeyStatusEnum.DEFAULT) &&
      params?.originTranslationTarget?.status === LanguageStatusEnum.REJECTED &&
      !!data?.isEditAfterDraftRejected
    ) {
      return 'text-bubble__title--modified';
    } else if (
      data?.isEdited &&
      (data?.status === DraftKeyStatusEnum.EDITED ||
        data?.status === DraftKeyStatusEnum.DEFAULT ||
        data?.status === DraftKeyStatusEnum.INIT ||
        (params?.originTranslationTarget?.status !== LanguageStatusEnum.REJECTED && !!data?.isEditAfterDraftRejected) ||
        data?.status == null ||
        !data?.status)
    ) {
      return params?.originHeaderClass === 'text-bubble__title--default'
        ? 'text-bubble__title--modified'
        : params?.originHeaderClass;
    } else {
      if (!!data?.draftKeyStatus) {
        return data?.draftKeyStatus?.headerClass;
      }
      return params?.defaultHeaderClass;
    }
  }
}
