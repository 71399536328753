import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface TranslationData {
  key: string
  text: string
}

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private readonly translate$ = new Subject<TranslationData>()

  public emit(data: TranslationData) {
    this.translate$.next(data)
  }

  public getTranslateObs() {
    return this.translate$.asObservable()
  }
}
