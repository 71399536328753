import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IconName } from '@app/shared';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() isDisabled: boolean;
  @Input() icon: IconName;
  @Input() disabledIcon: IconName;
  @Input() isIconSuffix: boolean;
  @Input() isHiddenIcon: boolean;

  @Input() matIcon: string;
  @Input() matIconPosition: 'left' | 'right' = 'left';

  @HostBinding()
  @Input()
  btnClass: 'btn__outline--leading' | 'btn__secondary' | 'btn--tertiary' | string = 'btn__outline--leading';
  @Input() btnLabel: string;
  @Output() buttonClicked = new EventEmitter();
}
