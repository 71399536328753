import { Injectable } from '@angular/core';
import { FavieAuthService } from './favie-auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserRoleCheckerService {
  private readonly SUPER_ADMIN_ROLES = 'SUPER_ADMIN';

  constructor(private readonly favieAuthService: FavieAuthService) {}

  public isSystemAdmin(): boolean {
    const role = this.favieAuthService.getUserRoles();
    if (!role) {
      return false;
    }
    return role === this.SUPER_ADMIN_ROLES;
  }
}
