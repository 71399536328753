export function compareByAlphabet(a = '', b = '', sortDirection = 'ASC'): number {
  let first, second;
  if (sortDirection === 'ASC') {
    first = a;
    second = b;
  } else {
    first = b;
    second = a;
  }

  return (
    first?.localeCompare(second, undefined, {
      numeric: true,
      sensitivity: 'base',
    }) || 0
  );
}
