import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'removeSectionDomain',
})
export class RemoveSectionDomainPipe implements PipeTransform {
  constructor() {}
  transform(value: string, isRemoveSectionDomain: boolean, sectionName: string): string {
    const splittedName = value?.split('.');
    let output = value;
    if (!!sectionName && isRemoveSectionDomain && splittedName?.length > 1) {
      if (splittedName[0] === sectionName) {
        output = splittedName.slice(1, splittedName?.length).join('.');
      }
    }
    return output;
  }
}
