import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlossaryDialogItemComponent } from './glossary-dialog-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [GlossaryDialogItemComponent],
  imports: [CommonModule, TranslateModule, MatIconModule],
  exports: [GlossaryDialogItemComponent],
})
export class GlossaryDialogItemModule {}
