import { APP_INITIALIZER } from '@angular/core';
import { FavieTranslateService } from '../services/favie-translate.service';

export function initLanguage(translateService: FavieTranslateService): any {
  return () => translateService.initLanguage();
}

const initLanguageProvider = {
  provide: APP_INITIALIZER,
  useFactory: initLanguage,
  multi: true,
  deps: [FavieTranslateService],
};

export default initLanguageProvider;
