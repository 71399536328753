<mat-form-field
  appearance="outline"
  class="textarea__container w-100"
  [style.height]="textAreaHeight"
>
    <textarea
      matInput
      [id]="textAreaId"
      cdkTextareaAutosize
      #autosize="cdkTextareaAutosize"
      #textareaField
      class="textarea__element"
      cdkAutosizeMinRows="3"
      cdkAutosizeMaxRows="200"
      [maxLength]="inputMaxLength"
      [formControl]="control"
      (blur)="blurControl.emit(textareaField.value)"
      (keydown)="calculateTextAreaHeight()"
      (keyup)="calculateTextAreaHeight()"
      (mouseout)="calculateTextAreaHeight()"
    >
    </textarea>
</mat-form-field>
