import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { UserService } from '../../services';

@Directive({
  selector: '[appRoleVisibility]',
})
export class RoleVisibilityDirective implements AfterViewInit {
  @Input('appRoleVisibility') roles: string[] = [];

  constructor(private _elementRef: ElementRef, private _userService: UserService, private _renderer: Renderer2) {}

  ngAfterViewInit() {
    if (!this.roles?.length) {
      return;
    }
    if (!this.roles.includes(this._userService.getUserProfileValue()?.role)) {
      this._renderer.addClass(this._elementRef.nativeElement, 'd--none');
    }
  }
}
