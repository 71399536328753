import { Pipe, PipeTransform } from '@angular/core';
import { convertHTMLStringWithParams } from './text-editor.util';

@Pipe({
  name: 'textParam',
  standalone: true
})
export class TextParamPipe implements PipeTransform {

  transform(value: string, mentionParams: string[]): string {
    if (!mentionParams?.length) {
      return value;
    }

    return convertHTMLStringWithParams(mentionParams, value);
  }
}
