import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'excludeOption' })
export class ExcludeOptionPipe implements PipeTransform {
  public transform(data: any[], excluded: any, placeholder?: string): any[] {
    let isObjectArray = false;
    const result =  data.filter((item) => {
      if (typeof item === 'string') {
        return item !== excluded;
      }

      if (typeof item === 'object' && excluded) {
        isObjectArray = true;
        return item.id !== excluded.id;
      }

      return true;
    });

    if (!placeholder || !excluded) {
      return result;
    }

    if (isObjectArray) {
      return [{title: placeholder, id: null}, ...result, ];
    }

    return [placeholder, ...result];
  }
}
