<div class="filter d--flex align__items--center" #dropdownEl>
  <div class="default-label clickable d--flex align__items--center justify__content--end" (click)="isClicked = !isClicked">
    <mat-icon
      class="clickable icon--sm d--flex align__items--center"
      [class.rotate]="isClicked"
      [svgIcon]="ICON_NAMES.Filter"
    ></mat-icon>

    <span class="label text--md-50 dropdown__sort-selected--text" [class.dropdown__activated-sort-selected--text]="isClicked">{{
      defaultOption?.name | translate
    }}</span>
    <ng-container [ngTemplateOutlet]="dropdownMenuTmpl" *ngIf="isClicked" [ngTemplateOutletContext]="{ options: options }">
    </ng-container>
  </div>
</div>

<ng-template #dropdownMenuTmpl let-options="options">
  <div class="dropdownMenu">
    <ul>
      <li
        *ngFor="let option of options"
        class="dropdownMenu__item clickable hover-scale-none text--md-50 dropdown__sort-dropdown-menu dropdown__sort-dropdown-menu--text"
        (click)="optionClicked.emit(option)"
      >
        {{ option?.name | translate }}
      </li>
    </ul>
  </div>
</ng-template>
