import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguagesDropdownComponent } from './languages-dropdown.component';
import { DropdownModule } from '../dropdown/dropdown.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PipesModule } from 'src/shared/pipes';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [LanguagesDropdownComponent],
  imports: [CommonModule, DropdownModule, ScrollingModule, PipesModule, MatMenuModule],
  exports: [LanguagesDropdownComponent],
})
export class LanguagesDropdownModule {}
