export enum UserStatusEnum {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Blocked = 'BLOCKED',
  NoPermission = 'NO_PERMISSION',
  DisabledAccount = 'DISABLED_ACCOUNT',
  NotFound = 'NOT_FOUND',
  OK = 'OK',
  Deleted = 'DELETED',
  CompletedProfile = 'COMPLETE_PROFILE',
  TokenExpired = 'TOKEN_EXPIRED',
}

export enum LoginFireBaseErrorEnum {
  EmailExisted = 'auth/email-already-in-use',
  UserNotFound = 'auth/user-not-found',
  UserDisabled = 'auth/user-disabled',
  WrongPassword = 'auth/wrong-password',
  TooManyLoginRequests = 'auth/too-many-requests',
  InvalidActionCode = 'auth/invalid-action-code',
}
