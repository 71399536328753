<textarea
  type="text"
  #inputKeyNote
  #autoSize="cdkTextareaAutosize"
  class="key-note-input w-100"
  cdkTextareaAutosize
  [cdkAutosizeMinRows]="1"
  [cdkAutosizeMaxRows]="200"
  [ngModel]="note?.value"
  [maxlength]="maximumKeyNoteValue"
  (ngModelChange)="onKeyNoteChange($event)"
  (blur)="isBlur.emit()"
  (focusin)="isFocus.emit(true)"
  (focusout)="isFocus.emit(false)"
  (keydown)="calculateTextAreaHeight()"
  (keyup)="calculateTextAreaHeight()"
></textarea>
