import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from '../interfaces';

@Pipe({
  name: 'userName',
  standalone: true,
})
export class UserNamePipe implements PipeTransform {
  transform(user: IUser): string {
    return [user.firstName, user.lastName].filter((t) => !!t).join(' ');
  }
}
