import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { BehaviorSubject } from 'rxjs';
import { Subscribable } from 'src/shared/components/base-component/subscribable';
import { CommonConstant } from 'src/shared/constants/common.constants';
import { IUser } from 'src/shared/interfaces/user.interface';
import { STORAGE } from 'src/shared/storages/storages.inject';
import firebase from 'firebase/compat';

// tslint:disable:variable-name
@Injectable({ providedIn: 'root' })
export class FavieAuthService extends Subscribable {
  private readonly END_POINT = 'auth';

  protected token: string;
  protected refreshToken: string;
  protected user: any;

  protected _userProfile$ = new BehaviorSubject<IUser>(null);
  protected _firebaseUserCredential: firebase.auth.UserCredential;

  protected logged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(STORAGE)
    private readonly storage: Storage,
    private readonly http: HttpClient,
  ) {
    super();
    if (window.location.href.includes('auth')) {
      this.clearUserToken();
    } else {
      this.refreshUserToken();
      this.refreshFirebaseUserCredential();
    }
  }

  protected refreshUserToken() {
    const userToken = this.storage.getItem(CommonConstant.USER_TOKEN);
    if (userToken) {
      const token = JSON.parse(userToken);
      this.setUserToken(token);
    }
  }

  public setUserToken(userToken: { token: string; refreshToken: string }) {
    this.storage.setItem(CommonConstant.USER_TOKEN, JSON.stringify(userToken));
    this.token = userToken.token;
    this.refreshToken = userToken.refreshToken;
    this.user = jwtDecode(this.token);
    this.setLogged(true);
  }

  protected clearUserToken() {
    this.storage.removeItem(CommonConstant.USER_TOKEN);
    this.storage.removeItem(CommonConstant.FIREBASE_USER_CREDENTIAL);
    this.token = undefined;
    this.refreshToken = undefined;
    this.user = undefined;
    this._firebaseUserCredential = undefined;
    this._userProfile$.next(undefined);
    this.setLogged(false);
  }

  public setLogged(status: boolean) {
    this.logged$.next(status);
  }

  public getLogged() {
    return this.logged$.asObservable();
  }

  public getToken() {
    return this.token;
  }

  public getDecodedToken() {
    const token = this.token;
    return jwtDecode(token);
  }

  public getRefreshToken() {
    return this.refreshToken;
  }

  public setFirebaseUserCredential(firebaseUserCredential: firebase.auth.UserCredential) {
    this._firebaseUserCredential = firebaseUserCredential;
    this.storage.setItem(CommonConstant.FIREBASE_USER_CREDENTIAL, JSON.stringify(this._firebaseUserCredential));
  }

  protected refreshFirebaseUserCredential() {
    const firebaseUserCredential = this.storage.getItem(CommonConstant.FIREBASE_USER_CREDENTIAL);
    if (firebaseUserCredential) {
      const token = JSON.parse(firebaseUserCredential);
      this.setFirebaseUserCredential(token);
    }
  }

  public setUserProfile(userProfile: IUser) {
    this._userProfile$.next(userProfile);
  }

  public getUserProfile() {
    return this._userProfile$.getValue();
  }

  public getObsUserProfile() {
    return this._userProfile$.asObservable();
  }

  public logout() {
    this.clearUserToken();
  }

  public getUser() {
    return this.user;
  }

  public getUserId() {
    return this.user.id;
  }

  public getUserRoles() {
    return this.user.role;
  }

  public getUserUid() {
    return this.user.user_id;
  }

  public sendResetEmail(email: string) {
    return this.http.post(`${this.END_POINT}/send-reset-password`, {
      email,
    });
  }

  public resetPassword(token: string, password: string) {
    return this.http.post(`${this.END_POINT}/reset-password`, {
      token,
      password,
    });
  }
}
