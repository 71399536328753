import { AudioStatusEnum } from './audio.enum';

export const AUDIO_ICONS = {
  [AudioStatusEnum.Default]: 'audio-play.svg',
  [AudioStatusEnum.Playing]: 'audio-pause.svg',
  [AudioStatusEnum.Recording]: 'check-grey.svg',
  [AudioStatusEnum.Loading]: 'loading-circle.svg',
}

export const AUDIO_MAX_LENGTH = 60000;
