import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserRoleCheckerService } from '../services/auth/user-role-checker.service';

@Injectable()
export class SystemAdminGuard  {
  constructor(private readonly userRoleCheckerService: UserRoleCheckerService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const result = this.userRoleCheckerService.isSystemAdmin();
    return of(result);
  }
}
