import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeDom',
})
export class SanitizeDomPipe implements PipeTransform {
  constructor(private _domSanitizer: DomSanitizer) {}

  transform(value: string): any {
    if (!value) {
      return value;
    }

    return this._domSanitizer.bypassSecurityTrustHtml(value);
  }
}
