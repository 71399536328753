import { Injectable } from '@angular/core';
import { IUser, UserStatusEnum } from '@app/shared';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class UserApiService extends BaseApiService<any> {
  apiUrl = 'users';

  public getListUsers(pageSize: number, pageIndex: number, keyword?: string, status?: string | UserStatusEnum) {
    const params: any = {
      pageSize,
      pageIndex,
      keyword: keyword ? keyword : '',
    };
    let url = `${this.apiUrl}?pageSize=${pageSize}&pageIndex=${pageIndex}&sort=lastName&keyword=${params.keyword}`;
    if (status) {
      url += `&status=${status.toUpperCase()}`;
    }
    return this._httpClient.get(url);
  }

  public updateUser(userId: string, params) {
    return this.patch(params, `${this.apiUrl}/${userId}`);
  }

  public getUserInfo(userId: string): Observable<IUser> {
    return this._httpClient.get(`${this.apiUrl}/${userId}`);
  }

  public updateListProject(params) {
    return this._httpClient.post(`${this.apiUrl}/grant-access`, params);
  }

  public createUser(param: any) {
    return this.create(param, this.apiUrl);
  }

  public deleteUser(id: string) {
    return this._httpClient.delete(`${this.apiUrl}/${id}`);
  }

  public resetPassword(id: string) {
    return this._httpClient.post(`${this.apiUrl}/${id}/send-reset-password`, null);
  }

  public deactivateUser(id: string) {
    return this._httpClient.patch(`${this.apiUrl}/${id}`, { status: UserStatusEnum.Deactivated });
  }

  public activateUser(id: string) {
    return this._httpClient.patch(`${this.apiUrl}/${id}`, { status: UserStatusEnum.Active });
  }
}
