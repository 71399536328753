<div
  #textEditor
  class="editor__wrapper"
  [class.loading]="isLoading()"
>
  <div
    *ngIf="!isHideHeader"
    class="editor__title text--long--md--semibold wordwrap--break-word d--flex align__items--center justify__content--between"
    [ngClass]="headerClass || ''"
  >
    {{ headerName }}

    <div
      *ngIf="isHasHistory"
      [ngClass]="HISTORY_COLOR_CLASS[originalDataSource?.draftKeyStatus?.status]"
      class="btn__icon-rectangle btn__icon-rectangle--default clickable clickable__wrapper p--4 d--flex align__items--center justify__content--center border-radius--4"
      (click)="historyLog.emit()"
    >
      <mat-icon
        class="history-icon"
        [svgIcon]="ICON_NAMES.Clock"
      ></mat-icon>
    </div>
  </div>

  <div
    class="editor__content"
    [class.text-bubble__text-input--activated]="isActive"
  >
    <div class="editor__container editor">
      <div
        [class.d--none]="!isEditable"
        class="d--relative"
        *ngIf="isSlateEditorEnabled"
      >
        <app-ui-textarea
          *ngIf="isTextareaEnabled && isEditable"
          [textAreaId]="originalDataSource?.name"
          [control]="rawTextFormControl"
          (blurControl)="onBlurRawEditor($event)"
        ></app-ui-textarea>

        <div
          *ngIf="isTextareaEnabled && isEditable"
          class="mt-6 px-4 py-1 border border-purple-500 rounded-2xl w-fit"
        >
          HTML
        </div>

        <!--        <div *ngIf="translationStatus === TranslationStatusEnum.InProgress" class="editor-skeleton">-->
        <!--          <app-skeleton-->
        <!--            [isLoading]="true"-->
        <!--            [count]="skeletonRows"-->
        <!--          ></app-skeleton>-->
        <!--        </div>-->

        <div
          class="slate-editor-wrapper"
          [class.hidden]="isTextareaEnabled"
          [class.text-bubble__richtext-input--activated]="isActive"
        >
          <div class="slate-editor-toolbar">
            <app-text-editor-button
              *ngFor="let toolbarItem of toolbarItems"
              [active]="toolbarItem.active(toolbarItem.format)"
              (onMouseDown)="toolbarItem.action(toolbarItem.format)"
            ><span class="material-icons">{{ toolbarItem.icon }}</span>
            </app-text-editor-button>
          </div>

          <slate-editable
            class="slate-angular-editor"
            #slateEditor
            [ngStyle]="isTextareaEnabled && {border: 'none', 'content-visibility': 'hidden'}"
            [editor]="editor"
            [placeholder]="'TextEditor.Placeholder.Label' | translate"
            [(ngModel)]="editorValue"
            (ngModelChange)="slateEditorValueChange($event)"
            [blur]="onSlateEditorBlur"
            [paste]="onPaste"
            [renderElement]="renderElement"
            [renderText]="renderText"
            [keydown]="onKeydown"
            [click]="onFocus"
          >
          </slate-editable>
        </div>

        <div
          *ngIf="!isEditable || !!missingMentionParams?.length"
          class="editor__hint m__t--12 clickable text-bubble__text-input--variables"
        >
          <div class="editor__hint__params">
            <div class="label-maj-xs--medium text-bubble__text-input--variables__text">
              {{ 'TextEditor.MissingVariables.Label' | translate | uppercase }}
            </div>

            <div class="m__t--8 variables-section d--flex flex__wrap">
              <div
                class="m__r--4 m__b--4 clickable variables-section__item"
                [class.pointer-events-none]="isTextareaEnabled"
                *ngFor="let param of missingMentionParams"
                (click)="addMissingParam(param)"
              >
                <span class="slate-mention-view">{{ param }}</span>
              </div>
            </div>
          </div>

          <div class="editor__hint__message text-right label-min-md--regular text-bubble__text-input--variables__text">
            {{ (isTextareaEnabled
            ? 'TextEditor.Variables.Hint.RawText.Label'
            : 'TextEditor.Variables.Hint.RichText.Label') | translate }}
          </div>
        </div>
      </div>

      <div
        class="text--sm content clickable border--grey-light bg--grey-extra-light rounded-[6px] p-4"
        (click)="copyText($event)"
        [class.d--none]="!isSourceLanguage"
        [innerHTML]="originalDataSource?.value || originalDataSource?.name | sanitizeDom"
      >
      </div>

      <div
        class="text--sm content border--grey-light bg--grey-extra-light rounded-[6px] p-4"
        [class.clickable]="isEditable"
        (click)="onParagraphClicked()"
        [class.d--none]="isSlateEditorEnabled || isSourceLanguage"
        [innerHTML]="((retainContent || originalDataSource?.name) | textParam : mentionParams) | sanitizeDom"
      ></div>
    </div>

    <app-editor-footer
      [isTextareaEnabled]="isTextareaEnabled"
      [isSourceLanguage]="isSourceLanguage"
      [isEditable]="isEditable"
      [translationKey]="originalDataSource.name"
      [language]="targetLanguage"
      [allowAudio]="originalDataSource.allowAudio"
      [disableAudio]="!(retainContent && retainContent !== originalDataSource.name)"
      [hasAudio]="hasAudio"
      (translateText)="onTranslateText()"
      (generateAudio)="onGenerateAudio()"
    ></app-editor-footer>
    <ng-content></ng-content>
  </div>
</div>

<ng-template
  #mention
  let-context="context"
  let-viewContext="viewContext"
>
  <span
    slateElement
    [context]="context"
    [viewContext]="viewContext"
    class="slate-mention-view"
    [ngClass]="{ focus: context.selection }"
  >
    {{ context.element.character }}
  </span>
</ng-template>

<div
  #suggestionList
  class="slate-mention-suggestion-list fixed"
>
  <div
    *ngFor="let item of suggestions; let i = index"
    class="cursor-pointer"
    [ngClass]="{ active: i === activeIndex }"
    (mousedown)="mousedown($event, item)"
  >
    {{ item }}
  </div>
</div>
