import { UserRoleEnum } from '../enums'

export class PermissionUtil {
  public static canUserReviewDraft(role: UserRoleEnum) {
    const res = [
      UserRoleEnum.ADMIN,
      UserRoleEnum.DLM,
    ].includes(role)
    return res
  }
}
