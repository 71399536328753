import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IGlossaryItem } from '../glossary-setting-item/glossary-setting-item.interface';
import { AppRouteEnum } from 'src/shared/enums';

@Component({
  selector: 'app-glossary-dialog',
  templateUrl: './glossary-dialog.component.html',
  styleUrls: ['./glossary-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlossaryDialogComponent {
  public projectId: string;
  constructor(
    public dialogRef: DialogRef<GlossaryDialogComponent>,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public glossaryData: IGlossaryItem[],
  ) {
    this.projectId = this._activatedRoute?.snapshot?.params?.projectId;
  }

  onGoToSetting(): void {
    this.dialogRef.close();
    void this._router.navigate([AppRouteEnum.Glossary], { queryParams: { projectId: this.projectId } });
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
