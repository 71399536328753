import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '../svg-icon';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-wrapper',
  standalone: true,
  imports: [CommonModule, TranslateModule, SvgIconComponent],
  templateUrl: './dialog-wrapper.component.html',
  styleUrls: ['./dialog-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogWrapperComponent {
  @Input() dialogTitle: string;
  @Input() dialogRef: MatDialogRef<any>;
}
