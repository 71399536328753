<button
  type="button"
  class="btn__container clickable"
  [ngClass]="btnClass || ''"
  [class.btn--disabled]="isDisabled"
  [class.flex__direction--row--reverse]="isIconSuffix"
  (click)="buttonClicked.emit()"
>
  <mat-icon
    class="d--flex align__items--center icon--sm"
    *ngIf="!!icon && !isHiddenIcon && !(!!disabledIcon && isDisabled)"
    [svgIcon]="icon"
  ></mat-icon>
  <mat-icon
    class="d--flex align__items--center icon--sm"
    *ngIf="!!disabledIcon && isDisabled"
    [svgIcon]="disabledIcon"
  ></mat-icon>
  <mat-icon *ngIf="matIcon && matIconPosition === 'left'">{{ matIcon }}</mat-icon>
  {{ btnLabel | translate }}
  <mat-icon *ngIf="matIcon && matIconPosition === 'right'">{{ matIcon }}</mat-icon>
</button>
