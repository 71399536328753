import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { Editor, Range, createEditor } from 'slate';
import { AngularEditor, withAngular } from 'slate-angular';
import { withHistory } from 'slate-history';
import { TextEditorToolbarDirective } from './text-editor-toolbar.directive';
import { insertMention, withMentions } from './text-editor.const';
import { ITranslationSchema } from './text-editor.interface';
import {
  buildHtmlStringFromSlateEditor,
  getMissingParams,
  removeSearchText
} from './text-editor.util';
import { CommonConstant } from '@app/shared';

@Directive()
export abstract class TextEditorDirective extends TextEditorToolbarDirective {
  public mentionParams: string[];
  public missingMentionParams: string[];
  public isActive = false;
  public isContentChanged = false;
  public contentChanged$ = new Subject<{ [name: string]: string }>();
  public originalDataSource: ITranslationSchema;

  public editor = withMentions(withHistory(withAngular(createEditor())));
  public searchText = '';
  public suggestions: string[] = [];
  public target: Range;
  public activeIndex = 0;
  public trigger = '@';
  public lastActiveAnchor: Range = null;

  @Input() maxLength = CommonConstant.TEXT_EDITOR_MAX_LENGTH;

  @ViewChild('mention', { read: TemplateRef, static: true })
  public mentionTemplate: TemplateRef<any>;

  @ViewChild('suggestionList', { static: true })
  public suggestionList: ElementRef;

  constructor(
    protected _changeDetectorRef: ChangeDetectorRef,
    protected _renderer2: Renderer2,
  ) {
    super();
  }

  public renderElement = (element: any) => {
    if (element.type === 'mention') {
      return this.mentionTemplate;
    }

    return undefined;
  };

  public onKeydown = (event: KeyboardEvent) => {
    if (this.target) {
      switch (event.key) {
        case 'ArrowDown':
          event.preventDefault();
          if (this.activeIndex === this.suggestions.length - 1) {
            this.activeIndex = 0;
          } else {
            this.activeIndex++;
          }
          this._changeDetectorRef.detectChanges();
          break;
        case 'ArrowUp':
          event.preventDefault();
          if (this.activeIndex === 0) {
            this.activeIndex = this.suggestions.length - 1;
          } else {
            this.activeIndex--;
          }
          this._changeDetectorRef.detectChanges();
          break;
        case 'Tab':
        case 'Enter':
          event.preventDefault();
          removeSearchText(this.editor, this.searchText);
          insertMention(this.editor, this.suggestions[this.activeIndex]);
          this._changeDetectorRef.detectChanges();
          break;
        case 'Escape':
          event.preventDefault();
          this.target = null;
          this.updateSuggestionsLocation();
          this._changeDetectorRef.detectChanges();
          break;
      }
    }

    if (this.retainContent?.length > this.maxLength) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }
  };

  public slateEditorValueChange(value: Element[]) {
    this.retainContent = buildHtmlStringFromSlateEditor(value);

    if (this.isPasteEventTriggered) {
      this.rebuildEditorValueAfterPaste();
    }

    this.missingMentionParams = getMissingParams(this.retainContent, this.mentionParams);

    if (this.originalDataSource.value?.trim() !== this.retainContent?.trim() && this.isContentChanged) {
      this.contentChanged$.next({
        [this.originalDataSource?.name]: this.retainContent || '',
        // status: DraftKeyStatusEnum.WIP,
      });
    }

    const { selection, operations } = this.editor;
    if (selection) {
      this.lastActiveAnchor = {
        anchor: Editor.before(this.editor, selection.anchor),
        focus: selection.focus
      };
    }

    if (operations[0].type === 'insert_text' && operations[0].text === this.trigger) {
      this.target = structuredClone(this.lastActiveAnchor);
      this.searchText = '';
      this.activeIndex = 0;
      this.updateSuggestionsLocation();
      return;
    }

    if (selection && Range.isCollapsed(selection) && this.target) {
      const beforeRange = Editor.range(this.editor, this.target.anchor, selection.focus);
      const beforeText = Editor.string(this.editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^@(\w*)$/);
      if (beforeMatch) {
        this.searchText = beforeText.slice(1);
        this.activeIndex = 0;
        this.updateSuggestionsLocation();
        return;
      }
    }

    if (this.target) {
      this.target = null;
      this.updateSuggestionsLocation();
    }
  }

  updateSuggestionsLocation() {
    this.suggestions = this.missingMentionParams.filter(suggestion => {
      return !this.searchText || suggestion.toLowerCase().includes(this.searchText.toLowerCase());
    }).slice(0, 10);
    if (this.target && this.suggestions.length) {
      const nativeRange = AngularEditor.toDOMRange(this.editor, this.target);
      const rect = nativeRange.getBoundingClientRect();
      this._renderer2.removeClass(this.suggestionList.nativeElement, 'hidden');
      this._renderer2.setStyle(this.suggestionList.nativeElement, 'left', `${rect.left}px`);
      this._renderer2.setStyle(this.suggestionList.nativeElement, 'top', `${rect.top + 25}px`);
      this._changeDetectorRef.detectChanges();
      return;
    }
    this._renderer2.addClass(this.suggestionList.nativeElement, 'hidden');
    this._renderer2.removeStyle(this.suggestionList.nativeElement, 'left');
    this._renderer2.removeStyle(this.suggestionList.nativeElement, 'top');
  }

  public mousedown(event: MouseEvent, item: any) {
    event.preventDefault();
    removeSearchText(this.editor, this.searchText);
    insertMention(this.editor, item);
  }
}
