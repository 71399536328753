import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-language-toggle',
  templateUrl: './language-toggle.component.html',
  styleUrls: ['./language-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageToggleComponent {
  @Input() languages: string[];
  @Input() selectedLanguage: string;
  @Output() toggleClicked = new EventEmitter();

  onToggleClicked(event: Event, language: string) {
    event.stopImmediatePropagation();
    this.toggleClicked.emit(language);
  }
}
