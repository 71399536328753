import { ExportModeEnum } from '../enums';
import { addZero } from './date.util';

export function getExportFileName(languageCode: string, mode: ExportModeEnum, draftPublicationDate?: string) {
  const code = languageCode.split('-')?.length > 1 ? languageCode.split('-')[1] : languageCode;

  switch (mode) {
    case ExportModeEnum.DRAFT_TRANSLATION:
      return `Draft_Export_${code.toUpperCase()}_${getFormatDateName()}`;
    case ExportModeEnum.DRAFT_GITLAB_EMERAUDE_HISTORY:
      return `Draft_Export_History_${code.toUpperCase()}_${getFormatDateName(draftPublicationDate, true)}`;
    case ExportModeEnum.DRAFT_CSV_202_HISTORY:
      return `Draft_Export_History_${code.toUpperCase()}_${getFormatDateName(draftPublicationDate, true)}`;
  }
}

export function getFormatDateName(draftPublicationDate?: string, hasHoursMinutes?: boolean) {
  let _newDate = new Date();
  if (!!draftPublicationDate) {
    _newDate = new Date(draftPublicationDate);
  }
  const year = _newDate.getFullYear().toString().slice(2, 5);
  const month = addZero(_newDate.getMonth() + 1);
  const date = addZero(_newDate.getDate());
  const hours = _newDate.getHours();
  const minutes = _newDate.getMinutes();

  if (hasHoursMinutes) {
    return `${year}${month}${date}${hours}${minutes}`;
  } else {
    return `${year}${month}${date}`;
  }
}
