import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CommonConstant } from '../constants/common.constants';

export class CustomValidatorUtils {
  static valueMatchWithAutoCompleteField(options: Array<any>): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (options) {
        const selectboxValue = control.value;
        const currentValue = options?.filter((item) => item.label === selectboxValue.label);
        if (currentValue.length) {
          return null;
        } else {
          return { ValueNotMatch: true };
        }
      } else {
        return null;
      }
    };
  }
  static validatorPassword(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const inputPatternRegex = CommonConstant.REGEX_STRONG_PASSWORD;
      const isValid = control.value && inputPatternRegex.test(control.value);
      return control.value && !isValid ? { passwordInvalid: true } : null;
    };
  }
  static validatorConfirmPassword(inputControl: AbstractControl, targetValue: unknown): { [key: string]: any } | null {
    const isValid = inputControl.value && inputControl.value === targetValue;
    return inputControl.value && !isValid ? { passwordNotSame: true } : null;
  }
}
