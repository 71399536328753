<app-audio-recorder
  *ngIf="audioUrl()"
  [audioUrl]="audioUrl()"
  [isEditable]="isEditable"
  [translationKey]="translationKey"
  [language]="language"
  [isLoading]="isLoading()"
  (removeAudio)="onRemoveAudio()"
></app-audio-recorder>

<!-- AI generator -->
<!-- <app-audio-recorder
  *ngIf="isAIAudioRecorderEnabled()"
  [translationKey]="translationKey"
  [isEditable]="true"
  [isAIAudio]="true"
  [language]="language"
  (removeAudio)="onRemoveAIAudio()"
  (audioUploaded)="onAIAudioUploaded($event)"
></app-audio-recorder> -->
<!-- End AI Audio -->

<!-- Audio recorder -->
<app-audio-recorder
  *ngIf="isAudioRecorderEnabled() && !isSourceLanguage"
  [translationKey]="translationKey"
  [isEditable]="true"
  [language]="language"
  [isLoading]="isLoading()"
  [showError]="showError"
  (removeAudio)="onRemoveAudio()"
  (audioRecorded)="onAddAudio($event)"
  (resetAudio)="onResetAudio()"
></app-audio-recorder>
<!-- End audio recorder -->

<div class="editor__footer">
  <div class="d--flex gap--8">
    <div
      *ngIf="isEditable && (!LANGUAGE_CONFIG_MAP[language] || LANGUAGE_CONFIG_MAP[language].DeepL)"
      [matTooltipPosition]="'above'"
      [matTooltip]="'Icon.DeepL' | translate"
      class="d&#45;&#45;flex d&#45;&#45;relative icon-copy clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle&#45;&#45;default"
      (click)="onTranslateText()"
    >
      <ng-container [ngSwitch]="translationStatus">
        <img
          *ngSwitchCase="TranslationStatusEnum.InProgress"
          src="assets/icons/png/icon-loading.png"
          alt="loading"
          class="clickable icon--md flex items-center infinite-spin"
        >

        <mat-icon
          *ngSwitchCase="TranslationStatusEnum.Error"
          class="clickable icon--md flex items-center"
          [svgIcon]="ICON_NAMES.TranslationError"
        ></mat-icon>

        <mat-icon
          *ngSwitchCase="TranslationStatusEnum.Success"
          class="clickable icon--md flex items-center"
          [svgIcon]="ICON_NAMES.TranslationSuccess"
        ></mat-icon>

        <mat-icon
          *ngSwitchDefault
          class="clickable icon--md flex items-center"
          [svgIcon]="ICON_NAMES.Translation"
        ></mat-icon>
      </ng-container>
    </div>

    <ng-container *ngIf="isEditable && allowAudio && hasAudio && !audioUrl()">
      <div
        class="flex relative icon-copy btn__icon-rectangle btn__icon-rectangle--default"
        [class.clickable]="!disableAudio"
        [class.clickable__wrapper]="!disableAudio"
        [class.loading]="isLoading()"
        (click)="!disableAudio && onRecordAudioClicked()"
        [matTooltipPosition]="'above'"
        [matTooltip]="'Icon.RecordVoice' | translate"
      >
        <app-svg-icon
          icon="speech-record"
          width="24"
          height="24"
        ></app-svg-icon>
      </div>

      <div
        *ngIf="!LANGUAGE_CONFIG_MAP[language] || LANGUAGE_CONFIG_MAP[language].ElevenLabs"
        class="flex relative icon-copy btn__icon-rectangle btn__icon-rectangle--default"
        [class.clickable]="!disableAudio"
        [class.clickable__wrapper]="!disableAudio"
        [class.loading]="isLoading()"
        (click)="!disableAudio && onAIAudioGenerationClicked()"
        [matTooltipPosition]="'above'"
        [matTooltip]="'Icon.RecordAI' | translate"
      >
        <app-svg-icon
          icon="speech-ai"
          width="24"
          height="24"
        ></app-svg-icon>
      </div>
    </ng-container>
  </div>

</div>
