import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonConstant } from '@app/shared';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _matSnackBar: MatSnackBar, private _translateService: TranslateService) {}

  public openSuccessSnackBar(messageKey?: string) {
    return this._matSnackBar.open(
      this._translateService.instant(messageKey ?? 'Global.Toast.Label.Success'),
      null,
      CommonConstant.SUCCESS_SNACKBAR_CONFIG,
    );
  }

  public openErrorSnackBar(messageKey?: string) {
    return this._matSnackBar.open(
      this._translateService.instant(messageKey ?? 'Global.Toast.Label.Error'),
      null,
      CommonConstant.FAILURE_SNACKBAR_CONFIG,
    );
  }
}
