import { environment } from '../../../../environments/environment'
import { AppRouteEnum } from '../../../../shared'

export class ProjectUtil {
  public static getGamePreviewUrl(projectId: string) {
    const url = new URL(
      [AppRouteEnum.GamePreview, projectId].join('/'),
      environment.url
    )
    return url.href
  }

  public static openGamePreview(projectId: string) {
    const url = ProjectUtil.getGamePreviewUrl(projectId)
    window.open(url, '_blank')
  }
}
