import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { first, switchMap } from 'rxjs/operators';
import { FirebaseService } from '../firebase/services/firebase.service';

export const InterceptorIgnoreAuthorization = 'X-Ignore-Language-Authorization';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private _firebaseService: FirebaseService;

  constructor(injector: Injector) {
    this._firebaseService = injector.get(FirebaseService);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return this._firebaseService.idToken.pipe(
      first(),
      switchMap((token: any) => {
        const ignore = request.headers.has(InterceptorIgnoreAuthorization);
        let headers = request.headers.delete(InterceptorIgnoreAuthorization);
        if (!ignore && token) {
          headers = headers.set('Authorization', `Bearer ${token}`);
        }

        return next.handle(request.clone({ headers }));
      }),
    );
  }
}
