import { UserRoleEnum } from '../enums';

export const UserRoleLabel = {
  [UserRoleEnum.ADMIN]: 'UserRole.Admin',
  [UserRoleEnum.DLM]: 'User.Role.DLM.Label',
  [UserRoleEnum.TRANSLATOR]: 'UserRole.Translator',
};

export const UserProfileRoleLabel = {
  [UserRoleEnum.ADMIN]: 'UserProfile.UserRole.Admin',
  [UserRoleEnum.DLM]: 'User.Role.DLM.Label',
  [UserRoleEnum.TRANSLATOR]: 'UserRole.Translator',
};
