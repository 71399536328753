import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { Observable, catchError, switchMap, tap } from 'rxjs'
import { environment } from '../../environments/environment'
import { SSOAuthService } from '../services/auth/sso-auth.service'
import { UserService } from '../services/user/user.service'

export const InterceptorIgnoreAuthorization = 'X-Ignore-Language-Authorization'

@Injectable()
export class SSOInterceptor implements HttpInterceptor {
  constructor(
    private readonly _router: Router,
    private readonly _ssoAuthService: SSOAuthService,
    private readonly _userService: UserService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { apiUrl } = environment
    const { url } = req

    const ignoreUrls = [
      'auth/renew-tokens'
    ]

    const allowAuth = url.startsWith(apiUrl)
      && !ignoreUrls.some(v => url.includes(v))

    let accessToken = this._ssoAuthService.accessToken
    if (accessToken && allowAuth) {
      let headers = new HttpHeaders()
      headers = headers.set('Authorization', `Bearer ${accessToken}`)

      const refreshToken = this._ssoAuthService.refreshToken
      const now = Date.now()
      const decoded = jwtDecode(accessToken) as JwtPayload
      const isDumpToken = true
        && decoded.iat === 1673436820
        && decoded.exp === 1673440420

      if (!isDumpToken && refreshToken && decoded.exp && decoded.exp * 1000 <= now) {
        return this._userService.renewToken(refreshToken).pipe(
          tap(() => {
            accessToken = this._ssoAuthService.accessToken
            headers = headers.set('Authorization', `Bearer ${accessToken}`)
          }),
          catchError((err) => {
            if (err.status && err.status >= 400) {
              this._ssoAuthService.logout()
            }
            throw err
          }),
          switchMap(() => next.handle(req.clone({ headers }))),
        )
      }

      return next.handle(req.clone({ headers }))
    }

    return next.handle(req)
  }
}
