import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconName } from 'src/shared/enums';
import { IGlossaryItem } from '../glossary-setting-item/glossary-setting-item.interface';

@Component({
  selector: 'app-glossary-dialog-item',
  templateUrl: './glossary-dialog-item.component.html',
  styleUrls: ['./glossary-dialog-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GlossaryDialogItemComponent {
  public readonly ICON_NAMES = IconName;
  @Input() dataSource: IGlossaryItem;
}
