import { HttpClient } from '@angular/common/http';
import { NgModule, TransferState } from '@angular/core';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FavieTranslateService } from './services/favie-translate.service';
import { TranslateLoaderService } from './translate-browser-loader.service';

export function createTranslateLoader(http: HttpClient, transferState: TransferState) {
  return new TranslateLoaderService('./assets/i18n/', '.json', http, transferState);
}

@NgModule({
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, TransferState],
      },
    }),
  ],
  providers: [FavieTranslateService],
})
export class TranslateBrowserModule {}
