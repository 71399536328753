<div #userProfileTemplate class="user-setting flex--1 d--flex justify__content--end" *ngIf="userProfile$ | async as userProfile">
  <button
    class="user-setting__button btn__default--leading clickable btn__container dropdown__user-selected"
    [class.dropdown__activated-user-selected]="isClicked"
    (click)="isClicked = !isClicked"
  >
    <span
      class="m__r--8 text--md user-name dropdown__user-selected--text"
      [class.dropdown__activated-user-selected--text]="isClicked"
      >{{ userProfile?.name }}</span
    >
    <mat-icon
      class="clickable icon--sm dropdown-icon d--flex align__items--center"
      [class.rotate]="isClicked"
      [svgIcon]="isClicked ? ICON_NAMES.UserProfileActiveChevronDown : ICON_NAMES.UserProfileChevronDown"
    ></mat-icon>
  </button>
  <ng-container
    [ngTemplateOutlet]="userDropdown"
    [ngTemplateOutletContext]="{ dataSource: userProfile }"
    *ngIf="isClicked"
  ></ng-container>
</div>

<ng-template #userDropdown let-dataSource="dataSource">
  <div class="user-dropdown">
    <div class="user-info user-dropdown__item p--16 d--flex justify__content--between dropdown__user-dropdown-menu">
      <div class="user-info__name text--md m__r--6 pointer-events--none dropdown__user-dropdown-menu-primary-text">
        {{ dataSource?.name }}
      </div>
      <div class="user-info__role text--md-50 pointer-events--none dropdown__user-dropdown-menu-secondary-text">
        {{ dataSource?.roleTitle | translate }}
      </div>
    </div>

    <div
      class="language-section user-dropdown__item p--16 d--flex justify__content--end align__items--center dropdown__user-dropdown-menu"
    >
      <div class="language-label text--md m__r--6 pointer-events--none d--none">
        {{ 'Global.Button.ChooseLanguage.Label' | translate }}
      </div>
      <div class="language-switches clickable">
        <app-language-toggle
          [languages]="supportedLanguages"
          [selectedLanguage]="selectedLanguage"
          (toggleClicked)="onLanguageChanges()"
        ></app-language-toggle>
      </div>
    </div>

    <div
      class="language-section user-dropdown__item p--16 d--flex justify__content--between align__items--center dropdown__user-dropdown-menu"
    >
      <div class="language-label text--md m__r--6 pointer-events--none">
        {{ 'Global.Button.Appearance' | translate }}
      </div>
      <div class="language-switches clickable">
        <app-language-toggle
          [languages]="supportedThemes"
          [selectedLanguage]="selectedTheme"
          (toggleClicked)="onThemeChanges()"
        ></app-language-toggle>
      </div>
    </div>

    <div
      class="help-section user-dropdown__item p--16 d--flex justify__content--between clickable dropdown__user-dropdown-menu"
      (mousedown)="onClickHelp()"
    >
      <div class="help-label text--md m__r--6">
        {{ 'Global.Button.Help' | translate }}
      </div>
      <img class="clickable icon--md" [class.dark-mode]="selectedTheme === THEME_ENUM.DARK" src="/assets/icons/lightbulb.svg" />
    </div>

    <div
      *ngIf="isSupperAdmin && !disableAccountManagement"
      class="logout user-dropdown__item d--flex justify__content--between p--16 clickable dropdown__user-dropdown-menu"
      (click)="onAccountManagementClicked()"
    >
      <div class="logout-label text--md pointer-events--none dropdown__user-dropdown-menu-primary-text">
        {{ 'Global.Button.AccountManagement' | translate }}
      </div>
      <mat-icon class="clickable icon--md" [svgIcon]="ICON_NAMES.UserProfileChevronDown"></mat-icon>
    </div>

    <div
      class="logout user-dropdown__item d--flex justify__content--between p--16 clickable dropdown__user-dropdown-menu"
      (click)="onLogout()"
    >
      <div class="logout-label text--md pointer-events--none dropdown__user-dropdown-menu-primary-text">
        {{ 'Global.Button.Logout' | translate }}
      </div>
      <mat-icon class="clickable icon--md" [svgIcon]="ICON_NAMES.LogOut"></mat-icon>
    </div>
  </div>
</ng-template>
