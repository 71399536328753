import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
  ViewChild,
  signal,
} from '@angular/core';
import { ITranslationSchema } from './text-editor.interface';

import {
  buildEditorSlateSchema, buildHtmlStringFromSlateEditor, buildParams,
  convertTextWithParams,
  copyTextToClipboard, getMissingParams,
  isStringIncludesHTMLTags,
  replaceLineBreakCharacters,
} from './text-editor.util';

import { NgClass, NgForOf, NgIf, NgStyle, NgSwitch, NgSwitchCase, UpperCasePipe } from '@angular/common';
import { FormControl, FormsModule, Validators } from '@angular/forms';
import { MatIcon } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GoogleTranslateService } from '@app/core';
import { SanitizeDomModule, SkeletonComponent } from '@app/shared';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { catchError, debounceTime, filter, finalize, tap } from 'rxjs';
import { SlateEditable, SlateElement } from 'slate-angular';
import { CommonConstant } from 'src/shared/constants';
import { DraftKeyStatusEnum, IconName } from 'src/shared/enums';
import { IVariableSyntaxTypeInterface } from 'src/shared/interfaces/variable-syntax-type.interface';
import { DraftAssetService } from '../../../app/core/services/draft-asset.service';
import { TranslationService } from '../../../app/core/services/translation.service';
import { EditorFooterComponent } from './editor-footer/editor-footer.component';
import { TextEditorButtonComponent } from './text-editor-button.component';
import { HISTORY_COLOR_CLASS, TRANSLATION_PARAM_INDICATOR } from './text-editor.const';
import { TextEditorDirective } from './text-editor.directive';
import { TextParamPipe } from './text-param.pipe';
import { TextareaModule } from './textarea';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GoogleTranslateService],
  standalone: true,
  imports: [
    SanitizeDomModule,
    TranslateModule,
    UpperCasePipe,
    NgSwitch,
    MatIcon,
    NgSwitchCase,
    TextareaModule,
    NgClass,
    NgIf,
    SkeletonComponent,
    SlateEditable,
    FormsModule,
    SlateElement,
    NgStyle,
    NgForOf,
    TextParamPipe,
    TextEditorButtonComponent,
    EditorFooterComponent
  ]
})
export class SlateTextEditorComponent extends TextEditorDirective {
  public readonly ICON_NAMES = IconName;
  public readonly HISTORY_COLOR_CLASS = HISTORY_COLOR_CLASS;

  public isTextareaEnabled = false;
  public rawTextFormControl = new FormControl<string>('', Validators.required)

  // public readonly TranslationStatusEnum = TranslationStatusEnum;
  // public translationStatus = TranslationStatusEnum.Default;
  // public skeletonRows = 1;
  // public isDeepLSupported = false;

  @Input() isSourceLanguage = false;
  @Input() paramSyntax: IVariableSyntaxTypeInterface;
  @Input() isEditable: boolean;
  @Input() projectId: string;
  @Input() headerClass: string;
  @Input() isHideHeader = false;
  @Input() headerName: string;
  @Input() set targetLanguage(value: string) {
    if (!value) {
      return;
    }

    this._targetLanguage = value;
  }
  get targetLanguage(): string {
    return this._targetLanguage;
  }
  @Input() set mainLanguageTranslationSchema(value: ITranslationSchema) {
    if (!value) {
      return;
    }

    this._validateEditorType(value, this._staticDatasource);
    this._mainLanguageTranslationSchema = value;
  }
  get mainLanguageTranslationSchema(): ITranslationSchema {
    return this._mainLanguageTranslationSchema;
  }

  @Input() set staticDatasource(value: ITranslationSchema) {
    if (!value) {
      return;
    }

    this._staticDatasource = value;
    this._validateEditorType(this._mainLanguageTranslationSchema, value);
  }
  @Input() set dataSource(value: ITranslationSchema) {
    if (!value) {
      return;
    }

    this._parseEditorValuesFromSchema(value);
  }
  @Input() isHasHistory: boolean;
  @Input() hasAudio = false

  @Output() copyTextClicked = new EventEmitter();
  @Output() contentChanged = new EventEmitter();
  @Output() rawEditorChanged = new EventEmitter();
  @Output() blurEditor = new EventEmitter();
  @Output() historyLog = new EventEmitter<void>();

  @ViewChild('textEditor') textEditor: ElementRef;

  public isLoading = signal(false)

  private _mainLanguageTranslationSchema: ITranslationSchema;
  private _staticDatasource: ITranslationSchema;
  private _targetLanguage: string;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly renderer2: Renderer2,
    private readonly matSnackBar: MatSnackBar,
    private readonly translateService: TranslateService,
    private readonly draftAssetService: DraftAssetService,
    private readonly googleTranslateService: GoogleTranslateService,
    private readonly translationService: TranslationService,
  ) {
    super(cdr, renderer2)
  }

  onAfterViewInit(): void {
    this.subscribe(this.contentChanged$.pipe(debounceTime(CommonConstant.INPUT_DEBOUNCE_INTERVAL)), (res: { [name: string]: string }) => {
      this.originalDataSource.value = res[this.originalDataSource.name];
      this.contentChanged.emit(res);
    });

    this.subscribe(this.translationService.getTranslateObs().pipe(
      filter(v => v.key === this.originalDataSource.name),
      tap(data => {
        this.retainContent = data.text
      }),
    ))

    this.checkRawTextChange();
  }

  protected onDestroy() {
    super.onDestroy();
  }

  public checkRawTextChange(): void {
    this.subscribe(this.rawTextFormControl.valueChanges, (value: string) => {
      this.missingMentionParams = getMissingParams(value, this.mentionParams);
    });
  }

  public onParagraphClicked() {
    if (this.isEditable) {
      this.isSlateEditorEnabled = true;
      if (this.isTextareaEnabled) {
        setTimeout(() => {
          const textArea: HTMLTextAreaElement = document.getElementById(this.originalDataSource?.name) as HTMLTextAreaElement;
          textArea?.scrollIntoView({ block: 'center', behavior: 'smooth' });
          textArea?.focus();
        });
        return;
      }
      const text = convertTextWithParams(this.originalDataSource?.params, this.retainContent, this.paramSyntax);
      this.editorValue = buildEditorSlateSchema(text);
      this.focusSlateEditor();

      document.onvisibilitychange = () => {
        this.isSlateEditorEnabled = false;
        this._changeDetectorRef.detectChanges();
        document.onvisibilitychange = () => { };
      }
    }
  }

  public renderElement = (element: any) => {
    if (element.type === 'mention') {
      return this.mentionTemplate;
    }

    return undefined;
  };

  public addMissingParam(param: string) {
    if (!this.lastActiveAnchor?.focus) {
      return;
    }

    const [paragraphIndex, childrenIndex] = this.lastActiveAnchor.focus.path;
    const insertPosition = this.lastActiveAnchor.focus.offset;

    const nodeText = this.editorValue[paragraphIndex].children[childrenIndex].text;
    const childNodes = [
      {
        text: nodeText.slice(0, insertPosition)
      },
      {
        type: 'mention',
        character: param,
        children: [
          {
            text: ''
          }
        ]
      },
      {
        text: nodeText.slice(insertPosition)
      }
    ]
    const paragraphChildNodes = structuredClone(this.editorValue[paragraphIndex].children);
    const newParagraph = {
      ...this.editorValue[paragraphIndex],
      children: [
        ...paragraphChildNodes.slice(0, childrenIndex),
        ...childNodes,
        ...(paragraphChildNodes.length > 1 ? paragraphChildNodes.slice(childrenIndex + 1) : []),
      ]
    };
    this.editorValue = this.editorValue.map((value, index) => {
      if (index === paragraphIndex) {
        return newParagraph;
      }

      return value;
    })
    this.retainContent = buildHtmlStringFromSlateEditor(this.editorValue);
  }

  public onTranslateText() {
    if (this.isLoading()) {
      return
    }

    this.isLoading.set(true)

    this.subscribe(
      this.googleTranslateService.getGoogleTranslateValueByProject(
        this.projectId,
        this.mainLanguageTranslationSchema.value,
        this._targetLanguage,
      ).pipe(
        tap((value) => {
          this._handleAutoTranslation(value)
        }),
        catchError((error) => {
          this.matSnackBar.open(
            error?.error?.message || this.translateService.instant('Global.Toast.Label.Error'),
            null,
            CommonConstant.FAILURE_SNACKBAR_CONFIG,
          );
          throw error
        }),
        finalize(() => {
          this.isLoading.set(false)
        }),
      )
    )
  }

  private _handleAutoTranslation(value: string) {
    this.rawTextFormControl.setValue(value);
    const newSchema = {
      ...this.originalDataSource,
      value,
    };
    this.headerClass = 'text-bubble__title--modified';
    this._parseEditorValuesFromSchema(newSchema);
    this.contentChanged$.next({
      [this.originalDataSource?.name]: this.retainContent || '',
    });
    this.blurEditor.emit({
      [this.originalDataSource?.name]: this.retainContent || '',
    });

    this._changeDetectorRef.detectChanges();
  }

  // private _updateTranslationStatus(status: TranslationStatusEnum) {
  //   this.translationStatus = status;
  //   this._changeDetectorRef.detectChanges();
  // }

  public copyText(event: Event) {
    event.stopPropagation();
    if (this.isTextareaEnabled || !this.isEditable) {
      copyTextToClipboard(this.rawTextFormControl.value);
      this.copyTextClicked.emit(this.rawTextFormControl.value);
      return;
    }
    let text = this.retainContent;

    if (text.endsWith('\n')) {
      text = text.slice(0, -1);
    }
    copyTextToClipboard(text);
    this.copyTextClicked.emit(text);
  }

  public onFocus = () => {
    if (!this.isEditable) {
      return;
    }
    this.isContentChanged = true;
    this.isActive = true;
    this._changeDetectorRef.detectChanges();
  };

  public onSlateEditorBlur = () => {
    this.toggleSlateEditorView(false);
    if (!this.isEditable) {
      return;
    }
    this.isActive = false;
    if (this.originalDataSource.value?.trim() !== this.retainContent?.trim()) {

      if (this.isContentChanged) {
        this.contentChanged$.next({
          [this.originalDataSource?.name]: this.retainContent || '',
          status: DraftKeyStatusEnum.WIP
        });
      }

      this.blurEditor.emit({
        [this.originalDataSource?.name]: this.retainContent || '',
      });
      this.originalDataSource.value = this.retainContent;
    }

    this.isContentChanged = false;
  };

  public onBlurRawEditor(value: string) {
    this.toggleSlateEditorView(false);
    if (replaceLineBreakCharacters(value) !== replaceLineBreakCharacters(this._staticDatasource.value)) {
      this.blurEditor.emit({ [this.originalDataSource?.name]: value ? replaceLineBreakCharacters(value) : '' });
      this.rawEditorChanged.emit({ [this.originalDataSource?.name]: value ? replaceLineBreakCharacters(value) : '' });
    }
  }

  public onGenerateAudio() {
    if (this.isLoading()) {
      return
    }

    this.isLoading.set(true)
    const language = this.targetLanguage
    const key = this.originalDataSource.name
    const text = (this.retainContent || '')
      .replace(/{{2}\s*[\w-.]+\s*}{2}/g, '')

    this.subscribe(this.draftAssetService.generateAudio(language, key, text).pipe(
      catchError((error) => {
        alert(error.message || error)
        throw error
      }),
      finalize(() => {
        this.isLoading.set(false)
      }),
    ))
  }

  private _parseEditorValuesFromSchema(translationSchema: ITranslationSchema) {
    const { name, value, params } = translationSchema || {};
    if (!this.headerName) {
      this.headerName = name;
    }
    this.mentionParams = buildParams(params, this.paramSyntax);
    const text = convertTextWithParams(translationSchema.params, value, this.paramSyntax);
    this.editorValue = buildEditorSlateSchema(text);
    translationSchema.value = text.replaceAll(TRANSLATION_PARAM_INDICATOR, '');
    this.retainContent = translationSchema.value;
    this.originalDataSource = translationSchema;
    this.missingMentionParams = getMissingParams(this.retainContent, this.mentionParams);
    this._changeDetectorRef.detectChanges();
  }

  // enable the raw editor when the main language has html tags
  private _validateEditorType(mainLanguageSchema: ITranslationSchema, originalSchema: ITranslationSchema) {
    this.isTextareaEnabled = isStringIncludesHTMLTags(mainLanguageSchema?.value || originalSchema?.value);
    this.rawTextFormControl.setValue(replaceLineBreakCharacters(originalSchema?.value || ''));
  }

  /**
   * Save some time after blur to avoid UI changed immediately so that user can click on the icons
   * @param open
   * @private
   */
  private toggleSlateEditorView(open: boolean) {
    if (open) {
      this.isSlateEditorEnabled = true;
    } else {
      setTimeout(() => {
        this.isSlateEditorEnabled = false;
        this._changeDetectorRef.detectChanges();
      }, 300);
    }
  }
}
