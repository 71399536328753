<div
  class="header-notice"
  #editableSection
>
  <div class="header-notice__content">
    <div class="header-notice__icon icon__info">
      <img
        src="/assets/icons/warning-yellow.svg"
        alt="info"
      />
    </div>

    <div class="header-notice__text text--sm">
      <div
        class="default"
        [class.d--none]="isEditable"
      >
        <span
          class="title"
          #messageText
        ></span>
        <span class="caption--sm-50 update-at subtitle" *ngIf="messageText?.innerText?.length">
          ({{
          'Dashboard.UpdatedAt.Label' | translate : { at: originalDataSource?.updatedAt | date : 'dd/MM/yyyy' } |
          uppercase
          }})
        </span>
      </div>

      <div
        class="editor"
        [class.d--none]="!isEditable"
      >
        <div class="slate-editor-wrapper">
          <div class="slate-editor-toolbar">
            <app-text-editor-button
              *ngFor="let toolbarItem of toolbarItems"
              [active]="toolbarItem.active(toolbarItem.format)"
              (onMouseDown)="toolbarItem.action(toolbarItem.format)"
            ><span class="material-icons">{{ toolbarItem.icon }}</span>
            </app-text-editor-button>
          </div>
          <slate-editable
            class="slate-angular-editor"
            #slateEditor
            [class.focused]="isActive && isEditable"
            [editor]="editor"
            [placeholder]="'TextEditor.Placeholder.Label' | translate"
            [(ngModel)]="editorValue"
            (ngModelChange)="slateEditorValueChange($event)"
            [renderText]="renderText"
            [paste]="onPaste"
            [blur]="onBlur"
            [click]="onFocus"
          >
          </slate-editable>
        </div>
      </div>
    </div>

    <div
      *ngIf="iconVisible"
      class="header-notice__icon icon__edit clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default"
      (click)="onIconClicked()"
      [appRoleVisibility]="['ADMIN']"
    >
      <mat-icon
        class="clickable icon--md d--flex align-item--center"
        [svgIcon]="isEditable ? ICON_NAMES.GuideBubbleCheck : ICON_NAMES.GuideBubbleEdit"
      ></mat-icon>
    </div>
  </div>
</div>
