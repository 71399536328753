import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Pipe({
  name: 'prefixSection',
})
export class PrefixSectionPipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {}
  transform(value: string): string {
    if (!value || value === 'undefined' || value == null) {
      return this._translateService.instant('Draft.Filter.Value.NoSection.Label');
    }
    return `${this._translateService.instant('Draft.Filter.Value.Section.Label')}: ${value}`;
  }
}
