import { IconName } from '../enums';
import { DraftKeyStatusEnum, LanguageStatusEnum } from '../enums/language-status.enum';

export const DRAFT_STATUS_ICON_ASSETS = {
  [DraftKeyStatusEnum.DEFAULT]: {
    iconPath: IconName.DraftStatusDefault,
    status: DraftKeyStatusEnum.DEFAULT,
    headerClass: 'text-bubble__title--default',
  },
  [DraftKeyStatusEnum.EDITED]: {
    iconPath: IconName.DraftStatusDefault,
    status: DraftKeyStatusEnum.EDITED,
    headerClass: 'text-bubble__title--modified',
  },

  [DraftKeyStatusEnum.CLOSE]: {
    iconPath: IconName.DraftStatusClose,
    status: DraftKeyStatusEnum.CLOSE,
    tooltip: 'Draft.Key.Status.Close',
  },

  [DraftKeyStatusEnum.WIP]: {
    iconPath: IconName.DraftStatusWip,
    status: DraftKeyStatusEnum.WIP,
    headerClass: 'text-bubble__title--modified',
    tooltip: 'Draft.Key.Status.Tooltip.WIP',
  },

  [DraftKeyStatusEnum.VALIDATED]: {
    iconPath: IconName.DraftStatusValidated,
    status: DraftKeyStatusEnum.VALIDATED,
    headerClass: 'text-bubble__title--validated',
    tooltip: 'Draft.Key.Status.Tooltip.Validated',
  },

  [DraftKeyStatusEnum.REJECTED]: {
    iconPath: IconName.DraftStatusRejected,
    status: DraftKeyStatusEnum.REJECTED,
    headerClass: 'text-bubble__title--rejected',
    tooltip: 'Draft.Key.Status.Tooltip.Rejected',
  },
};

export const DRAFT_STATUS_NAME = {
  [LanguageStatusEnum.OPEN]: 'Draft.Status.Open.Label',
  [LanguageStatusEnum.EDITED]: 'Draft.Status.Edited.Label',
  [LanguageStatusEnum.TO_REVIEW]: 'Draft.Status.ToReview.Label',
  [LanguageStatusEnum.TO_DEPLOY]: 'Draft.Status.ToDeploy.Label',
  [LanguageStatusEnum.TO_MERGE]: 'Draft.Status.ToMerge.Label',
  [LanguageStatusEnum.PUBLISHED]: 'Draft.Status.Published.Label',
  [LanguageStatusEnum.REJECTED]: 'Draft.Status.Rejected.Label',
};
