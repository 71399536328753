import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { ButtonModule } from '../button';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  standalone: true,
  imports: [NgIf, ButtonModule, TranslateModule],
})
export class ConfirmDialogComponent {
  @Input() public title: string;
  @Input() public description = 'Level.Dialog.Delete.Description';
  @Input() public name: string;
  @Input() public isWarning: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
