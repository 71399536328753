<app-text-editor
  [paramSyntax]="paramSyntax"
  [headerName]="dataSource?.name | removeSectionDomain : isRemoveSectionDomain : dataSource?.section"
  [headerClass]="headerClass"
  [targetLanguage]="targetLanguage"
  [staticDatasource]="staticDatasource"
  [dataSource]="dataSource"
  [mainLanguageTranslationSchema]="mainLanguageTranslationSchema"
  [projectId]="projectId"
  [isSourceLanguage]="isSourceLanguage"
  [isEditable]="isRowEditable"
  [isHasHistory]="isHasHistory"
  [hasAudio]="hasAudio"
  (contentChanged)="onContentChanged($event)"
  (rawEditorChanged)="onRawEditorContentChanged($event)"
  (copyTextClicked)="copyTextClicked.emit($event)"
  (blurEditor)="blurEditor.emit($event)"
  (historyLog)="historyLog.emit()"
>
  <div
    *ngIf="(!!dataSource?.note?.value) && hasKeyNotes"
    class="key-note"
  >
    <app-key-notes
      [isAdmin]="false"
      [note]="dataSource?.note"
    ></app-key-notes>
  </div>
</app-text-editor>
