export const environment = {
  version: 'v1',
  production: false,
  url: 'https://staging.translation.ccbmvo.emeraude.games',
  apiUrl: 'https://api.staging.translation.ccbmvo.emeraude.games',
  passportIframeUrl: 'https://staging.947316.emeraude.games',
  defaultLanguage: 'en-uk',
  supportedLanguages: ['en-uk', 'fr-fr_be'],
  firebase: {
    apiKey: 'AIzaSyCzuWmECUp8rQorYbm-Y683Ug9SmVsYSGg',
    authDomain: 'translation-chanel.firebaseapp.com',
    projectId: 'translation-chanel',
    storageBucket: 'translation-chanel.appspot.com',
    messagingSenderId: '385511737519',
    appId: '1:385511737519:web:5a69a912ff6d9643c72c5a',
  },
  fileUploadMaximumFileSize: 5242880,
  ssoSignIn: {
    url: 'https://cid.clasp-infra.com/adfs/oauth2/authorize',
    responseType: 'code',
    clientId: '3858b58f-b68b-479a-8e32-d70ac7c91412',
    redirectUrl: 'https://staging.translation.ccbmvo.emeraude.games/sso',
  },
  ssoSignOut: {
    url: 'https://cid.clasp-infra.com/adfs/oauth2/logout',
  },
  appValidation: {
    isActive: false,
    password: '7316383386a7fd11b31263b802efb091d6103f4e94f6e2f1ca1e2da496173f3a',
  },
};
