import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { APP_INIT_TIMESTAMP } from './svg-icon.const';

@Component({
  selector: 'app-svg-icon',
  standalone: true,
  imports: [NgIf],
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() public icon: string;
  @Input() public iconHover: string;
  @Input() public isSpecialIcon: boolean; // icons that cannot be compressed

  @Input() set width(value: number) {
    if (!value) {
      return;
    }
    this._width = value;
  }

  @Input() set height(value: number) {
    if (!value) {
      return;
    }
    this._height = value;
  }

  @HostBinding('style.width.px')
  @HostBinding('style.min-width.px')
  get hostWidth() {
    return this._width;
  }

  @HostBinding('style.height.px')
  get hostHeight() {
    return this._height;
  }

  @Input() public alt: string;

  @Input()
  @HostBinding('class.active')
  public isActive = false;

  get timestamp(): string {
    return this._timestamp;
  }

  private _width = 24;
  private _height = 24;

  constructor(@Inject(APP_INIT_TIMESTAMP) private _timestamp: string) {}
}
