<div class="toggle d--flex">
  <div
    class="caption toggle__item"
    contenteditable="false"
    (click)="onToggleClicked($event, language)"
    [class.active]="language === selectedLanguage"
    *ngFor="let language of languages"
  >
    {{ language | uppercase }}
  </div>
</div>
