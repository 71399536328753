<div class="filter d--flex align__items--center" #dropdownEl>
  <div class="default-label clickable d--flex align__items--center justify__content--end" (click)="isClicked = !isClicked">
    <mat-icon class="clickable icon--sm d--flex align__items--center m__r--4" [svgIcon]="ICON_NAMES.SectionMenu"></mat-icon>
    <span
      class="label text--md-50 dropdown__sort-selected--text clamp--1"
      [class.dropdown__activated-sort-selected--text]="isClicked"
    >
      <span
        class="clamp--1"
        *ngIf="defaultOption?.id !== ALL_SECTIONS_OPTION.id && defaultOption?.id !== NO_SECTIONS_OPTION.id; else defaultOptionTpl"
      >
        {{ 'Draft.Filter.Value.Section.Label' | translate }} {{ selectedSectionIdx }}:
        {{ defaultOption?.name | translate }}
      </span>
      <ng-template #defaultOptionTpl>
        <span>{{ defaultOption?.name | translate }}</span>
      </ng-template>
    </span>

    <ng-container [ngTemplateOutlet]="dropdownMenuTmpl" *ngIf="isClicked" [ngTemplateOutletContext]="{ options: options }">
    </ng-container>
  </div>
</div>

<ng-template #dropdownMenuTmpl let-options="options">
  <div class="dropdownMenu">
    <div class="dropdownMenu__container">
      <div
        *ngFor="let option of options; index as index"
        class="dropdownMenu__item clickable hover-scale-none text--md-50 dropdown__sort-dropdown-menu dropdown__sort-dropdown-menu--text"
        (click)="onOptionClicked(option, index)"
      >
        <span
          class="clamp--1"
          *ngIf="option?.id !== ALL_SECTIONS_OPTION.id && option?.id !== NO_SECTIONS_OPTION.id; else allSectionsTpl"
        >
          {{ 'Draft.Filter.Value.Section.Label' | translate }} {{ index }}: {{ option?.name | translate }}
        </span>
        <ng-template #allSectionsTpl>
          <span>{{ option?.name | translate }}</span>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
