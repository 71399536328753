<div class="container d--flex align__items--center">
  <span class="date label-xxs-medium">
    {{ item?.updatedAt | customFormatDate : CustomDateFormatEnum.dateMonthYearHoursMinute }}</span
  >
  <div class="d--flex align__items--center gap--4 flex--1">
    <div class="flag d--flex align__items--center justify__content--center m__l--16">
      <img [src]="item?.language | flagByCode" alt="language-icon" />
    </div>
    <span class="draft-name flex--1 label-md-medium"> {{ item?.language | languageNameByCode }}</span>
    <div
      class="align__items--center justify__content--center clickable btn__icon-rectangle btn__icon-rectangle--default clickable clickable__wrapper"
      (click)="export()"
    >
      <mat-icon class="export" [svgIcon]="IconName.Export"></mat-icon>
    </div>
  </div>
</div>
