import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { DraftKeyStatusEnum } from '../../../shared/enums/language-status.enum';
import { BaseApiService } from './base-api.service';

@Injectable({ providedIn: 'root' })
export class DraftService extends BaseApiService<any> {
  apiUrl = 'drafts';

  private readonly currentDraftId$ = new BehaviorSubject<string>(null)

  public getCurrentDraftIdObs() {
    return this.currentDraftId$.asObservable()
  }

  public getCurrentDraftId() {
    return this.currentDraftId$.getValue()
  }

  public setCurrentDraftId(value: string) {
    this.currentDraftId$.next(value)
  }

  public updateDraftKeyStatus(draftId: string, draftItemId: string, status: DraftKeyStatusEnum) {
    const url = `${this.apiUrl}/${draftId}/items/${draftItemId}/status`;
    return this.put({ status }, url);
  }

  public historyLog(draftId: string, editedItemId: string) {
    const url = `${this.apiUrl}/${draftId}/items/${editedItemId}/history`;
    return this.getDetailsByCustomUrl(url).pipe(map((response) => response['items']));
  }
}
