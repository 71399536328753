import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { APP_TRANSLATION, UserStatusEnum } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-user-status',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './user-status.component.html',
  styleUrls: ['./user-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserStatusComponent {
  public APP_TRANSLATION_STATUS = APP_TRANSLATION;
  public userStatus: UserStatusEnum;
  public statusClass = '';
  constructor() {}

  @Input() set status(value: UserStatusEnum) {
    if (!value) {
      return;
    }

    this.userStatus = value;

    switch (value) {
      case UserStatusEnum.Active:
        this.statusClass = 'status--active';
        return;

      case UserStatusEnum.Pending:
        this.statusClass = 'status--pending';
        return;

      case UserStatusEnum.Blocked:
        this.statusClass = 'status--blocked';
        return;

      case UserStatusEnum.Deactivated:
        this.statusClass = 'status--deactivated';
        return;

      default:
        return;
    }
  }
}
