<div class="container d--flex flex__direction--column">
  <div class="header d--flex align__items--center clickable" [class.border-bottom]="isExpanded" (click)="expanded()">
    <span class="date label-xxs-medium">{{
      historyLog.createdAt | customFormatDate : CustomDateFormatEnum.dateMonthYearHoursMinute
    }}</span>
    <div class="status">
      <span class="label-md-medium">{{ 'Project.Detail.HistoryLog.ActionChange.Label' | translate }}</span>

      <span
        *ngIf="historyLog.action === HistoryLogActionEnum.StatusChange; else valueChange"
        class="label-md-medium m__l--4"
        [class]="historyLog.newValue?.toLowerCase()"
      >
        <ng-container [ngSwitch]="historyLog.newValue">
          <ng-container *ngSwitchCase="DraftKeyStatusEnum.WIP">
            {{ historyLog.newValue.toUpperCase() }}
          </ng-container>

          <ng-container *ngSwitchCase="null">
            {{ 'Project.Detail.HistoryLog.KeyStatusReset.Label' | translate }}
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{ historyLog.newValue.toLowerCase() }}
          </ng-container>
        </ng-container>
      </span>

      <ng-template #valueChange>
        <span class="label-md-medium static-label-modify m__l--4">{{
          'Project.Detail.HistoryLogItem.Modified.Label' | translate
        }}</span>
      </ng-template>
    </div>
  </div>
  <div class="label-xs-light content" *ngIf="isExpanded">
    <div
      *ngIf="historyLog.action === HistoryLogActionEnum.ValueChange; else historyValueChange"
      [innerHTML]="historyLog?.newValue"
    ></div>

    <ng-template #historyValueChange>
      {{ 'Project.Detail.HistoryLogItem.KeyValueAtTime.Label' | translate }}
    </ng-template>
  </div>
</div>
