import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  computed,
  signal
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FirebaseService } from 'src/shared/firebase/services';
import { SanitizeDomModule } from 'src/shared/pipes/sanitize-dom';
import WaveSurfer from 'wavesurfer.js';
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import { AUDIO_ICONS, AUDIO_MAX_LENGTH } from './audio.const';
import { AudioStatusEnum } from './audio.enum';
import { createWaveSurfer, formatProgress } from './audio.util';
import { SvgIconComponent } from 'angular-svg-icon';


@Component({
  selector: 'app-audio-recorder',
  standalone: true,
  imports: [
    SvgIconComponent,
    NgIf,
    NgClass,
    TranslateModule,
    SanitizeDomModule,
    SvgIconComponent,
  ],
  templateUrl: './audio-recorder.component.html',
  styleUrl: './audio-recorder.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AudioRecorderComponent implements OnChanges, AfterViewInit, OnDestroy {
  public readonly AudioStatusEnum = AudioStatusEnum;
  public progress = signal<string>(formatProgress(0));
  public audioStatus = signal<AudioStatusEnum>(AudioStatusEnum.Default);
  public audioIcon = computed(() => '/assets/icons/' + AUDIO_ICONS[this.audioStatus()]);
  public isMaxLengthViolated = signal<boolean>(false);
  public isAudioGenerationFailed = signal<boolean>(false);
  public isDeleteRequested$ = signal<boolean>(false);
  public micsWavesurfer = signal<WaveSurfer | null>(null);
  public recordingWavesurfer = signal<WaveSurfer | null>(null);
  public isAIGeneratingSpeech = signal<boolean>(false);
  public progressBarColor: string;
  public backgroundColor: string;
  public isIconVisible = false;

  private record: RecordPlugin;
  private _isAIAudio: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private audioCounter: any;

  @ViewChild('mics') mics: ElementRef<HTMLElement>;
  @ViewChild('recording') recording: ElementRef<HTMLElement>;

  @Input() showError = false

  @Input() set isAIAudio(value: boolean) {
    this._isAIAudio = value;
    if (!value) {
      this.progressBarColor = 'rgb(247, 183, 80)';
      this.backgroundColor = 'bg-[#ffefd6]';
    } else {
      this.progressBarColor = 'rgb(80, 144, 247)';
      this.backgroundColor = 'bg-[#e6effe]';
    }
  }
  get isAIAudio() {
    return this._isAIAudio;
  }
  @Input() translationKey: string;
  @Input() language: string;
  @Input() audioUrl: string;
  @Input() isEditable: boolean;
  @Input() isLoading = false

  @Output() audioRecorded = new EventEmitter<File>();
  // @Output() audioUploaded = new EventEmitter<string>();
  @Output() aiAudioUploaded = new EventEmitter<string>();
  @Output() removeAudio = new EventEmitter<void>();
  @Output() removeAIAudio = new EventEmitter<void>();
  @Output() resetAudio = new EventEmitter<void>();

  constructor(
    private readonly firebaseService: FirebaseService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showError && changes.showError.previousValue !== changes.showError.currentValue) {
      this._changeDetectorRef.detectChanges()
    }
    if (changes.audioUrl && changes.audioUrl.currentValue) {
      const regex = /\/ai\//gi
      const url = changes.audioUrl.currentValue
      const isAi = regex.test(url) || regex.test(decodeURIComponent(url))
      this.isAIAudio = isAi
    }
  }

  async ngAfterViewInit() {
    if (this.audioUrl) {
      this.initAudio();
    } else if (this.isEditable && !this.isAIAudio) {
      void this.initRecorder();
    } else if (this.isAIAudio) {
      void this.initAIAudio();
    }
    this.isIconVisible = true;
  }

  ngOnDestroy() {
    this.micsWavesurfer()?.destroy();
    this.recordingWavesurfer()?.destroy();
    this.record?.destroy();
    this.clearAudioCounterInterval();
  }

  public onBtnDeleteClicked() {
    this.showError = false;
    this.isDeleteRequested$.set(true);
  }

  public cancelDeleteRequest() {
    this.isDeleteRequested$.set(false);
  }

  public onResetAudio() {
    if (this.showError) {
      this.resetRecorder()
      this.resetAudio.emit()
      return
    }

    if (this.isMaxLengthViolated()) {
      this.resetRecorder();
    } else {
      this.resetAIGeneration();
    }
  }

  public confirmDeleteRequest() {
    this.removeAudio.emit();
    // this.removeAIAudio.emit()
    // this.audioUrl = null;
    // this.recordingWavesurfer.set(null);
    // this.micsWavesurfer.set(null)
    // this.record?.destroy();
  }

  public audioBtnClicked() {
    switch (this.audioStatus()) {
      case AudioStatusEnum.Default:
        void this.recordingWavesurfer().play();
        this.audioStatus.set(AudioStatusEnum.Playing);
        return;

      case AudioStatusEnum.Playing:
        this.recordingWavesurfer().pause();
        this.clearAudioCounterInterval();
        this.audioStatus.set(AudioStatusEnum.Default);
        return;

      case AudioStatusEnum.Recording:
        this.record.stopRecording();
        this.audioStatus.set(AudioStatusEnum.Default);
        return;
    }
  }

  private initAudio() {
    this.recordingWavesurfer.set(createWaveSurfer(this.recording.nativeElement, this.audioUrl, this.progressBarColor));
    this.recordingWavesurfer().on('timeupdate', (currentTime) => {
      this.progress.set(formatProgress(Math.round(currentTime) * 1000));
      this._changeDetectorRef.detectChanges();
    });

    this.recordingWavesurfer().on('interaction', (newTime) => {
      this.progress.set(formatProgress(Math.round(newTime) * 1000));
      this._changeDetectorRef.detectChanges();
    });

    this.recordingWavesurfer().on('finish', () => {
      this.audioStatus.set(AudioStatusEnum.Default);
      this._changeDetectorRef.detectChanges();
    });
  }

  private resetRecorder() {
    this.record?.destroy();
    this.micsWavesurfer()?.destroy();
    this.micsWavesurfer.set(null);
    this.recordingWavesurfer()?.destroy();
    this.recordingWavesurfer.set(null);
    this.isMaxLengthViolated.set(false);
    this.initRecorder();
  }

  private resetAIGeneration() {
    this.isAudioGenerationFailed.set(false);
    this.recordingWavesurfer()?.destroy();
    this.recordingWavesurfer.set(null);
    this.clearAudioCounterInterval();
    this.initAIAudio();
  }

  private async initRecorder() {
    this.micsWavesurfer.set(createWaveSurfer(this.mics.nativeElement, null, this.progressBarColor));
    this.audioStatus.set(AudioStatusEnum.Recording);
    await this.registerRecorder(this.micsWavesurfer());
    void this.record.startRecording();
  }

  private async initAIAudio() {
    this.isAIGeneratingSpeech.set(true);
    this.audioStatus.set(AudioStatusEnum.Loading);
    let counter = 0;
    this.audioCounter = setInterval(() => {
      counter += 1000;
      this.progress.set(formatProgress(counter));
    }, 1000);

    // simulate a http request call
    // setTimeout(() => {
    //   this.isAIGeneratingSpeech.set(false);
    //   const audioUrl = '';
    //   if (audioUrl) {
    //     this.aiAudioRecorded.emit(audioUrl);
    //   } else {
    //     this.isAudioGenerationFailed.set(true);
    //   }
    //   this.clearAIGenerationCounterInterval();
    // }, 5000);
  }

  private clearAudioCounterInterval() {
    if (this.audioCounter) {
      clearInterval(this.audioCounter);
      this.audioCounter = null;
    }
  }

  private async registerRecorder(waveSurfer: WaveSurfer) {
    this.record = waveSurfer.registerPlugin(RecordPlugin.create({
      scrollingWaveform: true,
      renderRecordedAudio: false
    }));

    this.record.on('record-end', async (blob) => {
      if (this.isMaxLengthViolated()) {
        return;
      }

      const fileName = `${Date.now()}_${this.translationKey.replaceAll('.', '_')}`;
      const file = new File([blob], `${fileName}.mp3`, { type: 'audio/mp3' });

      this.audioRecorded.emit(file)
    });

    this.record.on('record-progress', (time) => {
      this.progress.set(formatProgress(Math.round(time)));
      if (time >= AUDIO_MAX_LENGTH) {
        this.isMaxLengthViolated.set(true);
        this.record.stopRecording();
      }
    })
  }

  // private async uploadAudio(file: File, path: string) {
  //   const uploadedUrl = await this.firebaseService.uploadFile(file, path);
  //   this.audioUploaded.emit(uploadedUrl);
  // }

}
