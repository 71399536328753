import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, map, of, switchMap, tap } from 'rxjs';
import { DraftAssetApiService } from '../../apis/draft-asset-api.service';
import { DraftAssetType } from '../../modules/draft/enum/draft-asset-type.enum';
import { DraftService } from './draft.service';

@Injectable({ providedIn: 'root' })
export class DraftAssetService {
  private readonly items$ = new BehaviorSubject<any[]>([])
  private readonly sourceItems$ = new BehaviorSubject<any[]>([])

  private readonly change$ = new Subject<void>()

  constructor(
    private readonly draftAssetApiService: DraftAssetApiService,
    private readonly draftService: DraftService,
  ) { }

  public get draftId() {
    return this.items$.getValue()[0]?.draftId
  }

  public get audio() {
    return this.items$.getValue().find((v) => v.type === DraftAssetType.AUDIO)
  }

  public get sourceAudio() {
    return this.sourceItems$.getValue().find((v) => v.type === DraftAssetType.AUDIO)
  }

  public get onChange() {
    return this.change$.asObservable()
  }

  public getItemObs() {
    return this.items$.asObservable()
  }

  public getSourceItemObs() {
    return this.sourceItems$.asObservable()
  }

  public setSourceItem(value: any[]) {
    this.sourceItems$.next(value)
  }

  public getId() {
    return this.audio?.id
  }

  public find(draftId: string, isSource: boolean = false) {
    return this.draftAssetApiService.find(draftId).pipe(
      tap((data) => {
        if (isSource) {
          this.sourceItems$.next(data)
        } else {
          this.items$.next(data)
        }
      }),
    )
  }

  public create(draftId: string, type: DraftAssetType) {
    return this.draftAssetApiService.create(draftId, type).pipe(
    )
  }

  public addItem(key: string, file: File) {
    return of(null).pipe(
      switchMap(() => this.createDraftAssetAudio()),
      switchMap(() => this.draftAssetApiService.addItem(this.draftId, this.getId(), key, file)),
      tap((data) => {
        const tmp = this.audio
        tmp.newValue[data.key] = data.url
        this.items$.next(this.items$.getValue())
        this.change$.next()
      }),
    )
  }

  public removeItem(key: string) {
    return this.draftAssetApiService.removeItem(this.draftId, this.getId(), key).pipe(
      tap(() => {
        const tmp = this.audio
        delete tmp.newValue[key]
        this.items$.next(this.items$.getValue())
        this.change$.next()
      }),
    )
  }

  public generateAudio(language: string, key: string, text: string) {
    return of(null).pipe(
      switchMap(() => this.createDraftAssetAudio()),
      switchMap(() => this.draftAssetApiService.generateAudio(this.draftId, this.getId(), language, key, text)),
      tap((data) => {
        const tmp = this.audio
        tmp.newValue[data.key] = data.url
        this.items$.next(this.items$.getValue())
        this.change$.next()
      }),
    )
  }

  private createDraftAssetAudio() {
    return of(this.getId()).pipe(
      switchMap((id) => id
        ? of(id)
        : this.create(this.draftService.getCurrentDraftId(), DraftAssetType.AUDIO).pipe(
          tap((data) => {
            const arr = this.items$.getValue()
            arr.push(data)
            this.items$.next(arr)
          }),
          map((data) => data.id),
        )
      )
    )
  }
}
