export enum LanguageStatusEnum {
  OPEN = 'OPEN',
  DEFAULT = 'DEFAULT',
  EDITED = 'EDITED',
  IN_PROGRESS = 'IN_PROGRESS',
  WIP = 'WIP',
  TO_MERGE = 'TO_MERGE',
  TO_EDIT = 'TO_EDIT',
  TO_REVIEW = 'TO_REVIEW',
  TO_DEPLOY = 'TO_DEPLOY',
  REJECTED = 'REJECTED',
  PUBLISHED = 'PUBLISHED',
  PAGE_REVIEW_MODE = 'PAGE_REVIEW_MODE',
}

export enum DraftKeyStatusEnum {
  DEFAULT = 'DEFAULT',
  EDITED = 'EDITED',
  CLOSE = 'CLOSE',
  INIT = 'INIT',
  WIP = 'WIP',
  REJECTED = 'REJECTED',
  VALIDATED = 'VALIDATED',
  EDITED_AFTER_REJECTED = 'EDITED_AFTER_REJECTED',
}

export enum LanguageStatusProjectDashBoardEnum {
  EDITED = 'EDITED',
  TO_REVIEW = 'TO_REVIEW',
  REJECTED = 'REJECTED',
  PUBLISHED = 'PUBLISHED',
}

export enum TranslationErrorEnum {
  VALUE_EMPTY = 'VALUE_EMPTY',
  KEY_DELETED = 'KEY_DELETED',
  KEY_NOT_FOUND = 'KEY_NOT_FOUND',
  VARIABLE_UNKNOWN = 'VARIABLE_UNKNOWN',
  MISSING_KEY = 'MISSING_KEY',
  VALUE_CONFLICT = 'VALUE_CONFLICT',
  VARIABLE_MISSING = 'VARIABLE_MISSING',
  VARIABLE_NOT_USED = 'VARIABLE_NOT_USED',
  COMMON_ERROR = 'COMMON_ERROR',
  // do nothing
  FILE_FORMAT = 'FILE_FORMAT',
  FILE_HEADER_KEYS = 'FILE_HEADER_KEYS',
  FILE_VALUE_LANGUAGE_CODE = 'FILE_VALUE_LANGUAGE_CODE',
  UNKNOWN = 'UNKNOWN',
  NOT_READY_TO_PUBLISH = 'NOT_READY_TO_PUBLISH',
  REJECTED = 'REJECTED',
  WIP = 'WIP',
  HEADER_MAIN_MISSING = 'HEADER_MAIN_MISSING',
  HEADER_LANGUAGE_MISSING = 'HEADER_LANGUAGE_MISSING',
  FILE_EMPTY_CONTENT = 'FILE_EMPTY_CONTENT',
  HAVE_ACTIVATE_DRAFT = 'HAVE_ACTIVATE_DRAFT',
}
