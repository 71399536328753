import {
  DIALOG_DEFAULT_DISABLECLOSE,
  DIALOG_DEFAULT_HASBACKDROP,
  DIALOG_DEFAULT_PANELCLASS,
  DIALOG_DEFAULT_POSITION,
} from 'src/app/modules/language-detail/language-detail.const';
import { DialogModeEnum } from '../enums/dialog.enum';
import { TranslationErrorEnum } from '../enums/language-status.enum';

export const DefaultDialogConfig = {
  panelClass: DIALOG_DEFAULT_PANELCLASS,
  position: DIALOG_DEFAULT_POSITION,
  hasBackdrop: DIALOG_DEFAULT_HASBACKDROP,
  disableClose: DIALOG_DEFAULT_DISABLECLOSE,
};
export const DialogConfigConst = {
  [DialogModeEnum.IMPORT_DRAFT_ERROR]: {
    title: 'Draft.Conflict.ImportError.Title.Label',
    subTitle: 'Draft.Conflict.ImportError.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Draft.Conflict.ImportError.Cancel',
        btnClass: 'btn__dialog-cancel-button',
        actionMethod: function () {
          this.dialogRef.close(null);
        },
      },
      {
        btnLabel: 'Draft.Conflict.ImportError.Continue',
        btnClass: 'btn__dialog-continue-button',
        actionMethod: function () {
          this.dialogRef.close(DialogModeEnum.IMPORT_DRAFT_ERROR);
        },
      },
    ],
  },
  [DialogModeEnum.IMPORT_DRAFT_INFO]: {
    title: 'Draft.Conflict.ImportInfo.Title.Label',
    subTitle: 'Draft.Conflict.ImportInfo.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Global.Button.ClickToContinue',
        btnClass: 'btn__text',
        actionMethod: function () {
          // do nothing when there only info errors or the flow is blocked
          this.dialogRef.close(DialogModeEnum.IMPORT_DRAFT_INFO);
        },
      },
    ],
  },
  [DialogModeEnum.IMPORT_DRAFT_BLOCK_FLOW_DO_NOTHING]: {
    title: 'Draft.Conflict.ImportInfo.Title.Label',
    subTitle: 'Draft.Conflict.ImportInfo.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      disableClose: false,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Draft.Conflict.ImportInfo.ClickAnywhereToContinue',
        btnClass: 'btn__text',
        actionMethod: function () {
          // do nothing when there only info errors or the flow is blocked
          this.dialogRef.close(null);
        },
      },
    ],
  },
  [DialogModeEnum.UPDATE_DRAFT_ERROR]: {
    title: 'Draft.Conflict.UpdateError.Title.Label',
    subTitle: 'Draft.Conflict.UpdateError.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Draft.Conflict.UpdateError.Button.Cancel',
        btnClass: 'btn__dialog-cancel-button',
        actionMethod: function () {
          // stay at the current state after call the BE fixing flow
          this.dialogRef.close(DialogModeEnum.CANCEL_LITE_FIX);
        },
      },
      {
        btnLabel: 'Draft.Conflict.UpdateError.Button.Continue',
        btnClass: 'btn__dialog-continue-button',
        actionMethod: function () {
          // redirect to project detail page, FE handle this case
          this.dialogRef.close(DialogModeEnum.UPDATE_DRAFT_ERROR);
        },
      },
    ],
  },
  [DialogModeEnum.UPDATE_DRAFT_INFO]: {
    title: 'Draft.Conflict.UpdateInfo.Title.Label',
    subTitle: 'Draft.Conflict.UpdateInfo.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      disableClose: false,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Global.Button.Cancel',
        btnClass: 'btn__text',
        actionMethod: function () {
          this.dialogRef.close(DialogModeEnum.CANCEL);
        },
      },
      {
        btnLabel: 'Draft.Conflict.UpdateInfo.ClickAnywhereToContinue',
        btnClass: 'btn__text',
        actionMethod: function () {
          this.dialogRef.close(DialogModeEnum.UPDATE_DRAFT_INFO);
        },
      },
    ],
  },
  [DialogModeEnum.IMPORT_SOURCE]: {
    title: 'Draft.Conflict.ImportSource.Label',
    subTitle: 'Draft.Conflict.ImportError.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      disableClose: false,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Global.Button.ClickAnywhereToContinue',
        btnClass: 'btn__text',
        actionMethod: function () {
          this.dialogRef.close(DialogModeEnum.IMPORT_DRAFT_INFO);
        },
      },
    ],
  },
  [DialogModeEnum.IMPORT_SOURCE_HAVE_ACTIVATED_DRAFT]: {
    title: 'Draft.Conflict.HaveActivateDraft.Title.Label',
    subTitle: 'Draft.Conflict.HaveActivateDraft.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Global.Button.Cancel',
        btnClass: 'btn__dialog-cancel-button',
        actionMethod: function () {
          this.dialogRef.close(DialogModeEnum.IMPORT_DRAFT_INFO);
        },
      },
      {
        btnLabel: 'Global.Button.Continue',
        btnClass: 'btn__dialog-continue-button',
        actionMethod: function () {
          this.dialogRef.close(DialogModeEnum.IMPORT_DRAFT_ERROR);
        },
      },
    ],
  },
  [DialogModeEnum.PUBLISH_TRANSLATION]: {
    title: 'Draft.Conflict.PublishedConflict.Title.Label',
    subTitle: 'Draft.Conflict.PublishedConflict.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Global.Button.ContinueEditing',
        btnClass: 'btn__dialog-cancel-button',
        actionMethod: function () {
          this.dialogRef.close(DialogModeEnum.CANCEL_LITE_FIX);
        },
      },
      {
        btnLabel: 'Global.Button.ContinueAnyway',
        btnClass: 'btn__dialog-continue-button',
        actionMethod: function () {
          this.dialogRef.close(DialogModeEnum.PUBLISH_TRANSLATION);
        },
      },
    ],
  },
  [DialogModeEnum.UPDATE_DRAFT_KEY_STATUS]: {
    title: 'Draft.Conflict.UpdateDraftKeyStatus.Title.Label',
    subTitle: 'Draft.Conflict.UpdateDraftKeyStatus.Subtitle.Label',
    dialogConfigs: {
      ...DefaultDialogConfig,
      autoFocus: false,
    },
    actions: [
      {
        btnLabel: 'Draft.Conflict.UpdateDraftKeyStatus.Button.Cancel',
        btnClass: 'btn__dialog-cancel-button',
        actionMethod: function () {
          // stay at the current state and do nothing
          this.dialogRef.close(DialogModeEnum.UPDATE_DRAFT_KEY_STATUS_DO_NOTHING);
        },
      },
      {
        btnLabel: 'Draft.Conflict.UpdateDraftKeyStatus.Button.Continue',
        btnClass: 'btn__dialog-continue-button',
        actionMethod: function () {
          // redirect to project detail page, FE handle this case
          this.dialogRef.close(DialogModeEnum.UPDATE_DRAFT_KEY_STATUS);
        },
      },
    ],
  },
};

export const DraftDialogErrorsBlockingFlow = {
  [TranslationErrorEnum.FILE_FORMAT]: TranslationErrorEnum.FILE_FORMAT,
  [TranslationErrorEnum.FILE_HEADER_KEYS]: TranslationErrorEnum.FILE_HEADER_KEYS,
  [TranslationErrorEnum.FILE_VALUE_LANGUAGE_CODE]: TranslationErrorEnum.FILE_VALUE_LANGUAGE_CODE,
  [TranslationErrorEnum.UNKNOWN]: TranslationErrorEnum.UNKNOWN,
  [TranslationErrorEnum.FILE_EMPTY_CONTENT]: TranslationErrorEnum.FILE_EMPTY_CONTENT,
};

export const DialogConfigByStatus = {
  [TranslationErrorEnum.VALUE_EMPTY]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.KEY_DELETED]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.MISSING_KEY]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.KEY_NOT_FOUND]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.VALUE_CONFLICT]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.VARIABLE_UNKNOWN]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.VARIABLE_MISSING]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.VARIABLE_NOT_USED]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.HAVE_ACTIVATE_DRAFT]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.COMMON_ERROR]: DialogModeEnum.IMPORT_DRAFT_ERROR,
  [TranslationErrorEnum.FILE_FORMAT]: DialogModeEnum.IMPORT_DRAFT_INFO,
  [TranslationErrorEnum.FILE_HEADER_KEYS]: DialogModeEnum.IMPORT_DRAFT_INFO,
  [TranslationErrorEnum.FILE_VALUE_LANGUAGE_CODE]: DialogModeEnum.IMPORT_DRAFT_INFO,
  [TranslationErrorEnum.UNKNOWN]: DialogModeEnum.IMPORT_DRAFT_INFO,
  [TranslationErrorEnum.HEADER_MAIN_MISSING]: DialogModeEnum.IMPORT_DRAFT_INFO,
  [TranslationErrorEnum.HEADER_LANGUAGE_MISSING]: DialogModeEnum.IMPORT_DRAFT_INFO,
  [TranslationErrorEnum.FILE_EMPTY_CONTENT]: DialogModeEnum.IMPORT_DRAFT_INFO,
};
