import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import { filter, firstValueFrom } from 'rxjs';
import { ILoginForm } from '../../interfaces';

@Injectable()
export class FirebaseService {
  get idToken() {
    return this._angularFireAuth.idToken;
  }

  constructor(
    private readonly _angularFireAuth: AngularFireAuth,
    private readonly _angularFireStorage: AngularFireStorage,
    private readonly storage: AngularFireStorage,
  ) {
    this._angularFireAuth.authState
      .pipe(filter((user) => !!user))
      .subscribe((user) => this._angularFireAuth.updateCurrentUser(user));
  }

  public signInWithEmailAndPassword(formData: Partial<ILoginForm>): Promise<firebase.auth.UserCredential> {
    const { email, password } = formData;
    const value = firebase.auth.Auth.Persistence.LOCAL;

    return this._angularFireAuth.setPersistence(value).then((_) => {
      return this._angularFireAuth.signInWithEmailAndPassword(email, password);
    });
  }

  public async getFileNameAndSize(fileUrl: string) {
    const fileRef = this._angularFireStorage.refFromURL(fileUrl);
    const metadata = await firstValueFrom(fileRef.getMetadata());
    return { name: metadata.name, size: metadata.size, url: fileUrl };
  }

  public signOut() {
    void this._angularFireAuth.signOut();
  }

  public async changePassword(newPassword: string) {
    return (await this._angularFireAuth.currentUser).updatePassword(newPassword);
  }

  public async getCurrentUser() {
    return await this._angularFireAuth.currentUser;
  }

  public async reauthenticateWithCredential(password: string) {
    const currentUser = await this.getCurrentUser();
    return await currentUser.reauthenticateWithCredential(
      firebase.auth.EmailAuthProvider.credential(currentUser.email, password),
    );
  }

  public async uploadFile(file: File, path?: string): Promise<string> {
    path = path || ['public', 'image', 'tmp', `${Date.now()}_${file.name}`].join('/')
    const task: AngularFireUploadTask = this.storage.upload(path, file)
    const snapshot = await task.snapshotChanges().toPromise()
    const url = await snapshot.ref.getDownloadURL()
    return url
  }
}
