import { CommonModule, NgIf, UpperCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  signal
} from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { BaseComponent, IconName, SvgIconComponent } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, combineLatest, finalize, tap } from 'rxjs';
import { DraftAssetService } from '../../../../app/core/services/draft-asset.service';
import { AudioRecorderComponent } from '../audio-recorder/audio-recorder.component';
import { TranslationStatusEnum } from '../text-editor.enum';
import { MatTooltip } from '@angular/material/tooltip';
import { LANGUAGE_CONFIG_MAP } from '../../../constants/language-config-map.const';

@Component({
  selector: 'app-editor-footer',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    NgIf,
    TranslateModule,
    UpperCasePipe,
    SvgIconComponent,
    AudioRecorderComponent,
    MatTooltip
  ],
  templateUrl: './editor-footer.component.html',
  styleUrl: './editor-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorFooterComponent extends BaseComponent implements OnChanges {
  public readonly TranslationStatusEnum = TranslationStatusEnum;
  public readonly LANGUAGE_CONFIG_MAP = LANGUAGE_CONFIG_MAP;

  protected readonly ICON_NAMES = IconName;

  public translationStatus: TranslationStatusEnum

  public audioUrl = signal<string | null>(null);
  public aiAudioUrl = signal<string | null>(null);
  public isAudioRecorderEnabled = signal<boolean>(false);
  public isAIAudioRecorderEnabled = signal<boolean>(false);

  public isLoading = signal(false)

  public showError = false

  @Input() translationKey: string;
  @Input() language: string;
  @Input() isTextareaEnabled: boolean;
  @Input() isEditable: boolean;
  @Input() allowAudio = false;
  @Input() disableAudio = false;
  @Input() hasAudio = false;
  @Input() isSourceLanguage: boolean;

  @Output() translateText = new EventEmitter<void>()

  @Output() addItem = new EventEmitter<File>();
  @Output() removeItem = new EventEmitter<void>();
  @Output() generateAudio = new EventEmitter<void>();

  constructor(
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private readonly draftAssetService: DraftAssetService,
  ) {
    super()
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.isSourceLanguage?.firstChange) {
      this.updateAudioUrl()
    }
  }

  protected onInit() {
    this.updateAudioUrl()

    this.subscribe(combineLatest([
      this.draftAssetService.getItemObs(),
      this.draftAssetService.getSourceItemObs(),
    ]).pipe(
      tap(() => {
        this.updateAudioUrl()
      }),
    ))
  }

  public onTranslateText() {
    this.translateText.next()
  }

  private updateAudioUrl() {
    if (!this.translationKey) {
      return
    }

    const url = this.isSourceLanguage
      ? this.draftAssetService.sourceAudio?.newValue?.[this.translationKey]
      : this.draftAssetService.audio?.newValue?.[this.translationKey]

    this.audioUrl.set(url)
  }

  public onRecordAudioClicked() {
    if (this.isLoading()) {
      return
    }

    this.isAudioRecorderEnabled.set(true)
    // this._changeDetectorRef.detectChanges()
  }

  public onAudioUploaded(audioUrl: string) {
    // console.log(audioUrl);
  }

  public onAddAudio(file: File) {
    this.isLoading.set(true)

    this.subscribe(this.draftAssetService.addItem(this.translationKey, file).pipe(
      tap(() => {
        this.isAudioRecorderEnabled.set(false);
      }),
      catchError((error) => {
        // alert(error.message)
        this.showError = true
        throw error
      }),
      finalize(() => {
        this.isLoading.set(false)
        this._changeDetectorRef.detectChanges()
      }),
    ))
  }

  public onRemoveAudio() {
    this.isLoading.set(true)

    this.subscribe(this.draftAssetService.removeItem(this.translationKey).pipe(
      tap(() => {
        this.isAudioRecorderEnabled.set(false);
        this.audioUrl.set(null);
      }),
      catchError((error) => {
        alert(error.message)
        // this.showError = true
        throw error
      }),
      finalize(() => {
        this.isLoading.set(false)
        this._changeDetectorRef.detectChanges()
      }),
    ))
  }

  public onResetAudio() {
    this.showError = false
  }

  public onAIAudioGenerationClicked() {
    if (this.isLoading()) {
      return
    }

    this.generateAudio.next()
  }

  public onAIAudioUploaded(audioUrl: string) {
    this.aiAudioUrl.set(audioUrl);
  }

  public onRemoveAIAudio() {
    this.isAIAudioRecorderEnabled.set(false);
    this.aiAudioUrl.set(null);
    this._changeDetectorRef.detectChanges();
  }
}
