<div class="d--flex align__items--center gap--8 container" xmlns="http://www.w3.org/1999/html">
  <mat-icon class="icon-key-note m__t--4" [svgIcon]="IconName.KeyNotes"></mat-icon>
  <div
    class="key-note-input__wrapper flex--1"
    [class.focused]="isFocusing"
    [class.default]="!isFocusing && isAdmin"
    [class.disabled]="!isAdmin"
    *ngIf="isAddNewKeyNote || !!note?.value"
  >
    <app-text-area-wrapper
      [note]="note"
      [isAddNewKeyNote]="isAddNewKeyNote"
      (isFocus)="onFocus($event)"
      (isBlur)="onBlur()"
      (keyNoteChanged)="onKeyNoteChange($event)"
    >
    </app-text-area-wrapper>
  </div>

  <div *ngIf="!note?.value && !isAddNewKeyNote" class="d--flex align__items--center justify__content--between w-100">
    <span class="label-md-medium key-note-title">{{ 'Project.Detail.KeyNote.Comment.Label' | translate }}</span>
    <div
      class="d--flex align__items--center justify__content--center p--4 btn__icon-rectangle btn__icon-rectangle--default clickable clickable__wrapper btn-add-new"
      (click)="onAddKeyNote()"
    >
      <mat-icon class="icon-add-new-key-note" [svgIcon]="IconName.Plus2"></mat-icon>
    </div>
  </div>
</div>
