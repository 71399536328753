import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Observable, catchError } from 'rxjs'
import { CommonConstant } from '../constants'
import { SSOAuthService } from '../services/auth/sso-auth.service'

@Injectable()
export class ErrorsInterceptor implements HttpInterceptor {
  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly _ssoAuthService: SSOAuthService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error) => {
        console.log(error);
        this._snackBar.open(error?.error?.message || error?.message, null, {
          ...CommonConstant.FAILURE_SNACKBAR_CONFIG,
          verticalPosition: 'top',
          horizontalPosition: 'right',
        })
        if (error instanceof HttpErrorResponse) {
          const { status } = error
          if (status === 401) {
            this._ssoAuthService.logout()
          }
        }
        throw error
      }),
    )
  }
}
