import { IVariableSyntaxTypeInterface } from 'src/shared/interfaces/variable-syntax-type.interface';
import {
  QUILL_EDITOR_PADDING,
  SKELETON_GAP,
  SKELETON_ROW_HEIGHT,
  TRANSLATION_PARAM_INDICATOR
} from './text-editor.const';
import { htmlToSlate, slateToHtml } from '@slate-serializers/html';
import { Editor, Transforms } from 'slate';

function replaceParam(text: string, source: string, param: string, paramSyntax: IVariableSyntaxTypeInterface): string {
  return text.replaceAll(
    source,
    `${TRANSLATION_PARAM_INDICATOR}${paramSyntax?.open}${param}${paramSyntax?.close}${TRANSLATION_PARAM_INDICATOR}`
  );
}

function replaceParamWithUnclosedTag(
  text: string,
  source: string,
  param: string,
  paramSyntax: IVariableSyntaxTypeInterface,
): string {
  return text.replaceAll(source, `${TRANSLATION_PARAM_INDICATOR}${paramSyntax?.open}${param}${TRANSLATION_PARAM_INDICATOR}`);
}

export function convertTextWithParams(params: string[], text: string, paramSyntax: IVariableSyntaxTypeInterface): string {
  let result = text?.trim() || '';
  if (!result.length) {
    return result;
  }

  if (params?.length) {
    const paramFormat1 = (param) => `${paramSyntax?.open}${param}${paramSyntax?.close}`;
    const paramFormat2 = (param) => `${paramSyntax?.open} ${param} ${paramSyntax?.close}`;
    const paramFormat3 = (param) => `${paramSyntax?.open}${param}`;
    const singleParamSyntaxRegex = new RegExp(`(?<=${paramSyntax?.open})(.*?)(?=\s|$)`, 'g');

    (params || []).forEach((param) => {
      if (
        (text.includes(paramFormat1(param)) ||
          text.includes(paramFormat2(param)) ||
          ((text.match(singleParamSyntaxRegex) && !text.includes(`${paramFormat3(param)}`))) &&
        !text.includes(`${paramFormat1(param)}`))
      ) {
        if (text.includes(`${paramFormat2(param)}`)) {
          result = replaceParam(result, paramFormat2(param), param, paramSyntax);
        } else if (text.includes(paramFormat1(param))) {
          result = replaceParam(result, paramFormat1(param), param, paramSyntax);
        } else if (text?.match(singleParamSyntaxRegex) && !text.includes(`${paramFormat3(param)}`)) {
          result = replaceParamWithUnclosedTag(result, paramFormat3(param), param, paramSyntax);
        }
      }
    });
  }

  result = replaceLineBreakCharacters(result);

  return result;
}

export function convertHTMLStringWithParams(params: string[], text: string): string {
  let result = text?.trim() || '';
  if (!result.length || !params?.length) {
    return result;
  }

  params.forEach(param => {
    result = result.replaceAll(param, `<span class="slate-mention-view">${param}</span>`);
  });

  return result;
}

function removeEndLinebreak(text: string): string {
  if (text.endsWith('<br>')) {
    return removeEndLinebreak(text.slice(0, -4));
  }

  return text;
}

export function replaceLineBreakCharacters(text: string): string {
  if (!text?.length) {
    return text;
  }
  text = text.replaceAll('<br />', '<br>');
  text = text.replaceAll('<br/>', '<br>');
  text = text.replace(/(\\\\n|\\n|<br\s?\/>)/g, '<br>');
  text = text.replaceAll('\n', '<br>');
  text = text.replaceAll('\r', '');

  text = removeEndLinebreak(text);

  return text;
}

const defaultChildNode = [{
  type: 'paragraph',
  children: [
    {
      text: ''
    },
  ]
}]

export function buildEditorSlateSchema(text: string): any[] {
  const elements: any[] = defaultChildNode;

  if (!text) {
    return elements;
  }

  return mapParagraphToSchema(text.split('<br>'));

}

function mapParagraphToSchema(paragraphs: string[]): any[] {
  return paragraphs.map(paragraph => {
    const childNodes = htmlToSlate(paragraph).map((item: any) => mapTextWithParams(item.children[0])).flat();
    if (!childNodes?.length) {
      return defaultChildNode[0];
    }

    return {
      type: 'paragraph',
      children: childNodes
    }
  });
}

function mapTextWithParams(textObject: any): any[] {
  if (!textObject.text?.includes(TRANSLATION_PARAM_INDICATOR)) {
    return [textObject];
  }

  return textObject.text.split(TRANSLATION_PARAM_INDICATOR).map((subtext: string, index: number) => {
    if (index % 2 === 0) {
      return {
        text: subtext
      }
    }

    return {
      type: 'mention',
      character: subtext,
      children: [
        {
          text: '',
        }
      ]
    }
  })
}

export function buildHtmlStringFromSlateEditor(paragraphs: any[]): string {
  let htmlString = '';

  paragraphs.forEach((paragraph, index) => {
    paragraph.children.forEach(content => {
      if (content.text) {
        htmlString += replaceSemanticCharacters(slateToHtml([content]));
      }
      if (content.type === 'mention') {
        htmlString += content.character
      }
    });
    if (index < paragraphs.length - 1) {
      htmlString += '<br>'
    }
  });

  return htmlString;
}

function fallbackCopyTextToClipboard(text: string) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {}

  document.body.removeChild(textArea);
}
export function copyTextToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  void navigator.clipboard.writeText(text);
}

export function replaceSemanticCharacters(html: string) {
  if (!html) {
    return '';
  }
  const textArea = document.createElement('textarea');
  textArea.innerHTML = html;

  return textArea.value
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&sol;', '/')
    .replaceAll('&nbsp;', ' ')
}

export function calculateSkeletonRows(quillEditorClientHeight: number): number {
  let rows = 1;
  const contentHeight = quillEditorClientHeight - QUILL_EDITOR_PADDING;
  let skeletonHeight = SKELETON_ROW_HEIGHT;
  if (skeletonHeight >= contentHeight) {
    return 1;
  }
  while (skeletonHeight < contentHeight) {
    rows++;
    skeletonHeight += SKELETON_ROW_HEIGHT + SKELETON_GAP;
  }

  return rows;
}

export function countWordOrCharacter(text: string): number {
  const trimWords = text
    .replaceAll('\n', ' ')
    .split(' ')
    .map((word) => word.replaceAll(' ', ''))
    .filter((word) => !!word)
    .filter((word) => {
      const specialChars = /[`~!@#$%^&*()_+\-=\[\]{};':"\\|\d,.<>\/?]/gi;
      const allFoundCharacters = word.match(specialChars) || [];
      return word.length !== allFoundCharacters.length;
    });

  return trimWords.length;
}

export function isStringIncludesHTMLTags(str: string): boolean {
  if (!str) {
    return false;
  }
  const regex = /<(?!\/?(strong|em|u)\b)[^>]+>/g;
  return !!str.match(regex)?.length;
}

export function removeSearchText(editor: Editor, searchText: string) {
  const range = {
    anchor: {
      path: editor.selection.anchor.path,
      offset: editor.selection.anchor.offset - searchText.length - 1
    },
    focus: editor.selection.focus
  };
  Transforms.select(editor, range);
  Transforms.delete(editor);
}

export function getCountWords(content: string, editorValue: any, skipParamCheck: boolean = true): number {
  if (!content || !content?.trim()?.length) {
    return 0;
  }

  let opCount = 0;
  editorValue.forEach(paragraph => {
    paragraph.children.forEach(child => {
      opCount += countWordOrCharacter(child.text || '');
      if (skipParamCheck && child.type === 'mention') {
        opCount++;
      }
    })
  })
  return opCount;
}

export function buildParams(rawParams: string[], paramSyntax: IVariableSyntaxTypeInterface) {
  return (rawParams || []).map(param => {
    if (param?.length === 1 && paramSyntax?.open === paramSyntax?.close) {
      return `${paramSyntax?.open}${param}`;
    }
    return `${paramSyntax?.open}${param}${paramSyntax?.close}`;
  })
}

export function getMissingParams(content: string, mentionParams: string[]): string[] {
  if (!mentionParams?.length) {
    return [];
  }
  if (!content) {
    return mentionParams;
  }

  return mentionParams.filter(param => !content.includes(param));
}
