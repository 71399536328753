import { UserRoleEnum, UserStatusEnum } from '../enums';

export const APP_TRANSLATION = {
  [UserRoleEnum.OWNER]: 'User.Role.Label.SuperAmin',
  [UserStatusEnum.Pending]: 'User.Status.Label.Pending',
  [UserStatusEnum.Active]: 'User.Status.Label.Active',
  [UserStatusEnum.Deactivated]: 'User.Status.Label.Deactivated',
  [UserStatusEnum.Blocked]: 'User.Status.Label.Blocked',
  [UserRoleEnum.ADMIN]: 'User.Role.Admin.Label',
  [UserRoleEnum.DLM]: 'User.Role.DLM.Label',
  [UserRoleEnum.EDITOR]: 'User.Role.Translator.Label',
};
