import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { FavieAuthService } from '../services/auth/favie-auth.service';

@Injectable()
export class AuthGuard  {
  private readonly URL_LOGIN = '/auth';
  private readonly URL_GROUPS = '/groups';

  constructor(private readonly router: Router, private readonly favieAuthService: FavieAuthService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.favieAuthService.getToken()) {
      void this.router.navigateByUrl(this.URL_LOGIN);
      return of(false);
    }
    return of(true);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.favieAuthService.getToken()) {
      void this.router.navigateByUrl(this.URL_GROUPS);
      return false;
    }
    return true;
  }
}
