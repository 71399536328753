import { DraftKeyStatusEnum } from 'src/shared/enums';
import { Editor, Transforms } from 'slate';
import { MentionElement } from './text-editor.interface';

export const ALLOW_KEYBOARD_CODE = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowRight', 'Backspace'];
export const HISTORY_COLOR_CLASS = {
  [DraftKeyStatusEnum.DEFAULT]: 'svg-color-default',
  [DraftKeyStatusEnum.EDITED]: 'svg-color-edited',
  [DraftKeyStatusEnum.CLOSE]: 'svg-color-close',
  [DraftKeyStatusEnum.WIP]: 'svg-color-wip',
  [DraftKeyStatusEnum.REJECTED]: 'svg-color-rejected',
  [DraftKeyStatusEnum.VALIDATED]: 'svg-color-validated',
};

export const QUILL_EDITOR_PADDING = 48;
export const SKELETON_ROW_HEIGHT = 23;
export const SKELETON_GAP = 8;

export const TRANSLATION_PARAM_INDICATOR = 'translation-param-indicator';

export const withMentions = editor => {
  const { isVoid, isInline } = editor;
  editor.isInline = element => {
    return element.type === 'mention' ? true : isInline(element);
  };
  editor.isVoid = element => {
    return element.type === 'mention' ? true : isVoid(element);
  };
  return editor;
};

export const insertMention = (editor: Editor, character: string) => {
  const mention: MentionElement = {
    type: 'mention',
    character,
    children: [{ text: '' }]
  };
  Transforms.insertNodes(editor, mention);
};

export const isDeepLSupported = (language: string) => {
  const supportedLanguages = [
    'bg',
    'cs',
    'da',
    'de-DE_AT',
    'el',
    'en-AUX_NZ',
    'en-CA',
    'en-SG',
    'en',
    'en-EN',
    'en-US',
    'es-AR',
    'es-ES',
    'es-MX',
    'fi',
    'fr',
    'hu',
    'it',
    'ja',
    'ko',
    'nb',
    'nl',
    'pl',
    'pt-BR',
    'pt-PT',
    'ro',
    'ru',
    'sk',
    'sl',
    'sv',
    'tk',
    'uk',
    'zh-CN',
    'zh-SG',
  ];
  return supportedLanguages.some((supportedLanguage) => supportedLanguage === language);
}
