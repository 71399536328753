import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../pipes';
import { CustomDateFormatEnum, IconName } from '../../enums';
import { MatIconModule } from '@angular/material/icon';
import { IProjectDraftChangeLog } from '../../interfaces';

@Component({
  selector: 'app-project-draft-history-item',
  standalone: true,
  imports: [CommonModule, PipesModule, MatIconModule],
  templateUrl: './project-draft-history-item.component.html',
  styleUrls: ['./project-draft-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDraftHistoryItemComponent {
  public readonly CustomDateFormatEnum = CustomDateFormatEnum;
  public readonly IconName = IconName;

  @Input() item: IProjectDraftChangeLog;
  @Output() exportProjectDraftChangelog = new EventEmitter<void>();

  public export(): void {
    this.exportProjectDraftChangelog.emit();
  }
}
