import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonConstant } from 'src/shared/constants';
import { UserProfileRoleLabel } from 'src/shared/constants/user-role.const';
import { AppRouteEnum, AppThemeEnum, IconName } from 'src/shared/enums';
import { AuthService, UserService } from 'src/shared/services';
import { STORAGE } from 'src/shared/storages';
import { FavieTranslateService } from 'src/shared/translate/services/favie-translate.service';
import { toggleLanguage } from './user-profile.util';
import { IUser } from '@app/shared';
import { SSOAuthService } from '../../services/auth/sso-auth.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileComponent {
  public readonly ICON_NAMES = IconName;
  public isClicked = false;
  public supportedLanguages = CommonConstant.SUPPORTED_LANGUAGES;
  public supportedThemes = CommonConstant.SUPPORTED_THEMES;
  public selectedLanguage = CommonConstant.DEFAULT_LANGUAGE;
  public selectedTheme = CommonConstant.DEFAULT_THEME;
  public isSupperAdmin = false;
  public THEME_ENUM = AppThemeEnum;

  @ViewChild('userProfileTemplate') userProfile: ElementRef;

  @Input() disableAccountManagement = false;
  @Input() hasCustomLogout = false;

  @Output() logout = new EventEmitter();

  @HostListener('document:mousedown', ['$event'])
  public onClickOutSide(event) {
    if (!this.userProfile.nativeElement.contains(event.target)) {
      this.isClicked = false;
    }
  }
  public userProfile$: BehaviorSubject<IUser> = new BehaviorSubject(null);
  constructor(
    @Inject(STORAGE) private _storage: Storage,
    private _ssoService: SSOAuthService,
    private _userService: UserService,
    private _authService: AuthService,
    private _favieTranslateService: FavieTranslateService,
    private _router: Router,
  ) {
    this._userService.getProfile().subscribe((userProfile) => {
      userProfile['roleTitle'] = UserProfileRoleLabel[userProfile?.role] || 'UserProfile.UserRole.Admin';
      this.isSupperAdmin = this._userService.isSuperAdmin();
      this.userProfile$.next(userProfile);
    });

    this.selectedLanguage = this._storage.getItem(CommonConstant.LANGUAGE_TOKEN) || this.selectedLanguage;
    this.selectedTheme = this._storage.getItem(CommonConstant.THEME_TOKEN) || this.selectedTheme;
  }

  onLogout() {
    const chanelUrl = environment.ssoSignOut.url;
    this._authService.chanelLogout().subscribe(() => {
      const openedWindow = window.open(chanelUrl, '', 'width=700,height=500');
      this.logout.emit();
      if (!this.hasCustomLogout) {
        this._ssoService.logout();
      }
    });
  }

  onAccountManagementClicked() {
    this._router.navigateByUrl(AppRouteEnum.AccountManagement);
  }

  onClickHelp() {
    this._router.navigate([AppRouteEnum.FAQ]);
  }

  onLanguageChanges() {
    if (this.supportedLanguages.length <= 1) {
      return;
    }
    const language = toggleLanguage(this.selectedLanguage, this.supportedLanguages);
    this._favieTranslateService.switchLanguage(language);
    this._storage.setItem(CommonConstant.LANGUAGE_TOKEN, language);
    this.selectedLanguage = language;
  }

  onThemeChanges() {
    if (this.supportedThemes.length <= 1) {
      return;
    }
    const theme = toggleLanguage(this.selectedTheme, this.supportedThemes);
    this._storage.setItem(CommonConstant.THEME_TOKEN, theme);
    this.selectedTheme = theme as AppThemeEnum;
    if (this.selectedTheme === AppThemeEnum.DARK) {
      document.body.classList.add('dark-theme');
      return;
    }
    document.body.classList.remove('dark-theme');
  }
}
