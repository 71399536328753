import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, debounceTime, distinctUntilChanged, finalize, map, of, tap } from 'rxjs';
import { BaseApiService } from 'src/app/core/services/base-api.service';
import { CommonConstant } from 'src/shared/constants';
import { AppRouteEnum, UserRoleEnum } from 'src/shared/enums';
import { IUser } from 'src/shared/interfaces';
import { ACCESS_TOKEN, BrowserStorage, STORAGE } from 'src/shared/storages';
import { SSOAuthService } from '../auth/sso-auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseApiService<IUser> {
  protected apiUrl = 'users';
  private _userProfile$ = new BehaviorSubject<IUser>(null);

  public logged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public userRole$ = new BehaviorSubject<string>(undefined);

  constructor(
    @Inject(STORAGE) private _storage: BrowserStorage,
    private _ssoService: SSOAuthService,
    private _router: Router,
  ) {
    super();
  }

  public getProfile(): Observable<IUser> {
    if (this._userProfile$.value) {
      return of(this._userProfile$.value);
    }

    return this._httpClient.get<IUser>(`${this.apiUrl}/me`).pipe(
      tap((user: any) => this.setUserProfile(user)),
      map((user: any) => ({ ...user })),
    );
  }

  public updateUserProfile(user: Partial<IUser>): Observable<IUser> {
    this._userProfile$.next({
      ...this._userProfile$.value,
      ...user,
    })
    return this.patch(user, `${this.apiUrl}/me`);
  }

  public setUserProfile(userProfile: IUser) {
    this.setLogged(true);
    this.userRole$.next(userProfile.role);
    this._userProfile$.next(userProfile);
  }

  public getUserProfile(): Observable<IUser> {
    return this._userProfile$.asObservable();
  }

  public getUserProfileValue(): IUser {
    return this._userProfile$.value;
  }

  public logout() {
    this._ssoService.clearTokens();
    this._router.navigate([AppRouteEnum.Welcome]);
  }

  public ssoSignIn() {
    const url = CommonConstant.DEFAULT_CHANEL_SIGN_IN_URL_PRO;
    return new Observable((obs) => {
      const openedWindow = window.open(url, '', 'width=700,height=500');
      const timer = setInterval(() => {
        if (openedWindow.closed) {
          clearInterval(timer);
          if (this._storage.getItem(ACCESS_TOKEN)) {
            this.getProfile()
              .pipe(
                catchError((err) => {
                  obs.error();
                  throw err;
                }),
                finalize(() => {
                  obs.complete();
                  clearInterval(timer);
                }),
              )
              .subscribe((userProfile) => {
                this.setUserProfile(userProfile);
                obs.next({ token: this._ssoService.getToken(), refreshToken: this._ssoService.refreshToken });
              });
          }
        }
      }, 1000);
    });
  }

  public renewToken(refreshToken: string) {
    const url = 'auth/renew-tokens'
    return this.create({ refreshToken }, url).pipe(
      map((v) => v as any),
      tap((res) => {
        this._ssoService.accessToken = res.access_token
      }),
    );
  }

  public setLogged(status: boolean) {
    this.logged$.next(status);
  }

  public getLogged() {
    return this.logged$.asObservable().pipe(debounceTime(200), distinctUntilChanged());
  }

  public getLoggedValue() {
    return this.logged$.value;
  }

  public isSuperAdmin(): boolean {
    const roles = [UserRoleEnum.SUPER_ADMIN, UserRoleEnum.ADMIN];
    return roles.includes(this.userRole$.getValue() as UserRoleEnum);
  }
}
