<div
  class="local-spinner__wrapper full-height"
  [class.loading]="showSpinner$ | async"
  [ngClass]="backgroundType === SpinnerType.Button ? 'btn-loading' : ''"
  [style]="{ width, minWidth, height, minHeight }"
>
  <div
    *ngIf="showSpinner$ | async"
    class="local-spinner__background"
    [ngClass]="backgroundType === SpinnerType.Button ? 'absolute' : 'fixed'"
  ></div>

  <div
    *ngIf="showSpinner$ | async"
    class="local-spinner__image"
    [ngClass]="backgroundType === SpinnerType.Button ? 'absolute' : 'fixed'"
  >
    <svg
      width="145"
      height="145"
      viewBox="0 0 107 107"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.055 53.375C106.055 82.4563 82.4796 106.031 53.3983 106.031C24.317 106.031 0.741943 82.4563 0.741943 53.375C0.741943 24.2937 24.317 0.718597 53.3983 0.718597C82.4796 0.718597 106.055 24.2937 106.055 53.375Z"
        fill="var(--primary-bgc)"
        stroke="black"
      />
      <path
        d="M58.5453 40.0618C64.6701 49.0488 64.7902 57.5655 58.9055 66.0572C63.289 68.6142 69.744 66.3024 74.4877 60.2826C76.2691 61.3134 78.0956 62.3693 79.917 63.4201C75.4035 72.1469 64.3998 75.8949 54.8474 72.0168C53.3662 71.4164 52.1803 71.3663 50.6591 72.0268C41.587 75.9949 30.7885 72.3571 25.5695 63.5202C27.3709 62.4343 29.1723 61.3485 31.0437 60.2176C35.0369 65.7669 40.1108 68.0437 46.8211 66.4825C41.2567 58.5513 40.4861 49.4842 46.5959 40.5022C41.592 38.3004 35.8425 40.4721 31.0137 46.5319C29.2874 45.4961 27.531 44.4402 25.5544 43.2593C28.6369 37.76 33.3155 34.6576 39.2702 33.4867C43.1732 32.7211 47.0262 33.2064 50.8092 34.6075C51.9651 35.0379 53.5614 35.0779 54.6973 34.6326C64.2948 30.8946 74.9881 34.4124 79.967 43.2443C78.2257 44.3001 76.4793 45.361 74.5228 46.5469C72.3461 43.3744 69.5489 40.8724 65.7359 40.1319C63.6142 39.7215 61.3425 40.0568 58.5453 40.0568V40.0618ZM52.7958 44.0149C47.2014 49.0488 48.042 59.0717 52.8858 62.5794C57.9298 57.2353 57.9598 49.6443 52.7958 44.0149Z"
        fill="var(--primary-text--color)"
      />
    </svg>
  </div>

  <ng-content></ng-content>

  <div></div>
</div>
