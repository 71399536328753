import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DraftAssetType } from '../modules/draft/enum/draft-asset-type.enum';

interface AddResult {
  key: string
  url: string
}

@Injectable({ providedIn: 'root' })
export class DraftAssetApiService {
  constructor(private readonly http: HttpClient) { }

  public find(draftId: string) {
    const url = `drafts/${draftId}/assets`
    return this.http.get<any>(url, {
      params: {
        draftId,
        withPublicUrl: true,
      }
    })
  }

  public create(draftId: string, type: DraftAssetType) {
    const url = `drafts/${draftId}/assets`
    return this.http.post<any>(url, { type })
  }

  public addItem(draftId: string, draftAssetId: string, key: string, file: File) {
    const url = `drafts/${draftId}/assets/${draftAssetId}/item`
    const data = new FormData()
    data.append('key', key)
    data.append('file', file)
    return this.http.post<AddResult>(url, data)
  }

  public removeItem(draftId: string, draftAssetId: string, key: string) {
    const url = `drafts/${draftId}/assets/${draftAssetId}/item/delete`
    return this.http.post(url, { key })
  }

  public generateAudio(draftId: string, draftAssetId: string, language: string, key: string, text: string) {
    const url = `drafts/${draftId}/assets/${draftAssetId}/audio`
    return this.http.post<AddResult>(url, { language, key, text })
  }
}
