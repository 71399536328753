<div class="p__t--35 p__x--40 page-header">
  <div class="flex align__items--center justify__content--between p__b--40">
    <div class="back flex--1 d--flex justify__content--start">
      <button
        class="btn__container btn__top-nav-back clickable"
        (click)="onBack()"
      >
        <mat-icon
          class="m__r--8 icon--sm d--flex align__items--center"
          [svgIcon]="IconName.Back"
        ></mat-icon>
        <span class="text--md">{{ 'Global.Button.Back' | translate }}</span>
      </button>
    </div>

    <div
      class="logo clickable d--flex justify__content--start"
      (click)="onBackToMainDashBoard()"
    >
      <!-- <mat-icon class="icon--xxxl clickable d--flex align__items--center" [svgIcon]="IconName.Logo"></mat-icon> -->
    </div>

    <div class="user-setting flex--1">
      <app-user-profile [disableAccountManagement]="true"></app-user-profile>
    </div>
  </div>

  <div class="page-title">
    {{ title }}
  </div>

  <div class="page-description m__t--10">
    {{ description }}
  </div>

  <ng-content></ng-content>
</div>
