import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { AppRouteEnum, IconName } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { UserProfileModule } from '../user-profile/user-profile.module';

@Component({
  selector: 'app-main-page-header',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    UserProfileModule,
  ],
  templateUrl: './main-page-header.component.html',
  styleUrls: ['./main-page-header.component.scss'],
})
export class MainPageHeaderComponent {
  @Input() title = 'Title'
  @Input() description = 'Description'
  @Input() useCustomBackAction = false

  @Output() back = new EventEmitter<void>()

  protected readonly IconName = IconName;

  constructor(
    private readonly _router: Router,
  ) { }

  public onBack() {
    if (!this.useCustomBackAction) {
      history.back();
      return
    }

    this.back.next()
  }

  public onBackToMainDashBoard() {
    this._router.navigate([AppRouteEnum.Home]);
  }
}
