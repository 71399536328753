import Lottie from 'lottie-web';
import { LottieOptions } from 'ngx-lottie/lib/symbols';
export const LOTTIE_ANIMATIONS = {
  PUBLISHED: {
    path: '/assets/lottie-jsons/published-json/chanel-published-animation.json',
    assetsPath: '/assets/lottie-jsons/published-json/images/',
    autoplay: false,
    loop: false,
  },
  FLOWER_TOP_RIGHT: {
    path: '/assets/lottie-jsons/flower-top-right-json/flower-top-right-animation.json',
    assetsPath: '/assets/lottie-jsons/flower-top-right-json/images/',
    autoplay: true,
    loop: false,
  },
  FLOWER_BOTTOM_LEFT: {
    path: '/assets/lottie-jsons/flower-bottom-left-json/flower-bottom-left-animation.json',
    assetsPath: '/assets/lottie-jsons/flower-bottom-left-json/images/',
    autoplay: true,
    loop: false,
  },
};

export class LottieConstant {
  public static readonly OPTIONS = {
    player: () => Lottie,
    useCache: true,
  };
}
