import { UserSortEnum } from './filter-option.enum';
import { IEntityOption } from '../../interfaces';

export const USER_SORT_OPTION_TRANSLATIONS = {
  [UserSortEnum.All]: 'AccountManagement.Filter.Status.All',
  [UserSortEnum.Active]: 'AccountManagement.Filter.Status.Active',
  [UserSortEnum.Deactivated]: 'AccountManagement.Filter.Status.Deactivated',
  [UserSortEnum.Pending]: 'AccountManagement.Filter.Status.Pending',
  [UserSortEnum.Blocked]: 'AccountManagement.Filter.Status.Blocked',
};

export const USER_SORT_OPTIONS = Object.values(UserSortEnum).map(
  (option) =>
    ({
      id: option,
      name: USER_SORT_OPTION_TRANSLATIONS[`${option}`],
    } as IEntityOption),
);
