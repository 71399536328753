import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../storages';

@Injectable({ providedIn: 'root' })
export class SSOAuthService {
  private _accessToken: string;
  private _refreshToken: string;

  constructor(
    private readonly _router: Router,
  ) {
    this.reloadTokens();
  }

  public get accessToken() {
    return this._accessToken;
  }

  public set accessToken(value: string) {
    this.setItem(ACCESS_TOKEN, value);
    this._accessToken = value;
  }

  public get refreshToken() {
    return this._refreshToken;
  }

  public set refreshToken(value: string) {
    this.setItem(REFRESH_TOKEN, value);
    this.refreshToken = value;
  }

  public reloadTokens() {
    const accessToken = this.getItem(ACCESS_TOKEN);
    const refreshToken = this.getItem(REFRESH_TOKEN);
    if (accessToken) {
      this._accessToken = accessToken
    }
    if (refreshToken) {
      this._refreshToken = refreshToken;
    }
  }

  public getToken() {
    this.reloadTokens();
    return this._accessToken;
  }

  public setTokens(tokens: { token: string; refreshToken: string }) {
    this.setItem(ACCESS_TOKEN, tokens.token);
    this.setItem(REFRESH_TOKEN, tokens.refreshToken);
    this._accessToken = tokens.token;
    this._refreshToken = tokens.refreshToken;
  }

  public clearTokens() {
    this.removeItem(ACCESS_TOKEN);
    this.removeItem(REFRESH_TOKEN);
    this._accessToken = undefined;
    this._refreshToken = undefined;
  }

  public logout() {
    this.clearTokens();
    this._router.navigate(['/welcome']);
  }

  //#region internal

  private getItem(key: string) {
    const value = localStorage.getItem(key)
    return value
  }

  private setItem(key: string, value: string) {
    localStorage.setItem(key, value)
  }

  private removeItem(key: string) {
    localStorage.removeItem(key)
  }

  //#endregion
}
