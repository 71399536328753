export enum ExportModeEnum {
  DRAFT_TRANSLATION = 'DRAFT_TRANSLATION',
  DRAFT_GITLAB_EMERAUDE_HISTORY = 'DRAFT_GITLAB_EMERAUDE_HISTORY',
  DRAFT_CSV_202_HISTORY = 'DRAFT_CSV_202_HISTORY',
}

export enum TypeExportFileEnum {
  CSV = 'csv',
  XLSX = 'xlsx',
}
