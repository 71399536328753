import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonConstant } from '..';
import { environment } from '../../environments/environment';
import { AppRouteEnum } from '../enums';
import { STORAGE } from '../storages';

@Injectable()
export class AppValidationGuard  {
  constructor(
    @Inject(STORAGE)
    private readonly storage: Storage,
    private readonly router: Router,
  ) {}

  canActivate(): boolean {
    if (environment.appValidation.isActive && !this.storage.getItem(CommonConstant.APP_VALIDATION_TOKEN)) {
      this.router.navigate([AppRouteEnum.AppValidation]);
      return false;
    }

    return true;
  }
}
