import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inputError',
})
export class InputErrorPipe implements PipeTransform {
  transform(value: any): string {
    if (value.hasOwnProperty('required')) {
      return 'Global.Error.Required';
    } else if (value.hasOwnProperty('minlength')) {
      return 'Global.Error.MinLength';
    } else if (value.hasOwnProperty('maxlength')) {
      return 'Global.Error.MaxLength';
    } else if (value.hasOwnProperty('email') || value.hasOwnProperty('emailPattern')) {
      return 'Global.Error.Email';
    } else if (value.hasOwnProperty('pattern')) {
      return 'Global.Error.RequiredPattern';
    } else if (value.hasOwnProperty('passwordNotSame')) {
      return 'Global.Error.PasswordNotSame';
    } else if (value.hasOwnProperty('passwordInvalid')) {
      return 'Global.Error.PasswordInvalid';
    } else if (value.hasOwnProperty('passwordIncorrect')) {
      return 'Global.Error.PasswordIncorrect';
    } else if (value.hasOwnProperty('max')) {
      return 'Global.Error.Max';
    } else if (value.hasOwnProperty('min')) {
      return 'Global.Error.Min';
    } else if (value.hasOwnProperty('ValueNotMatch')) {
      return 'Global.Error.ValueNotMatch';
    } else if (value.hasOwnProperty('emailExisted')) {
      return 'Global.Error.EmailExisted';
    } else if (value.hasOwnProperty('emailNotFound')) {
      return 'Global.Error.EmailNotFound';
    } else if (value.hasOwnProperty('tokenExpired')) {
      return 'Global.Error.TokenExpired';
    } else if (value.hasOwnProperty('tooManyLoginRequests')) {
      return 'Global.Error.TooManyLoginRequests';
    } else if (value.hasOwnProperty('emailBlocked')) {
      return 'Global.Error.EmailBlocked';
    } else if (value.hasOwnProperty('emailDeactivated')) {
      return 'Global.Error.EmailDeactivated';
    } else if (value.hasOwnProperty('emailPending')) {
      return 'Global.Error.EmailPending';
    } else if (value.hasOwnProperty('subdomainExisted')) {
      return 'Global.Error.SubdomainExisted';
    } else if (value.hasOwnProperty('subdomainInvalid')) {
      return 'Global.Error.SubdomainInvalid';
    } else if (value.hasOwnProperty('hexColorInvalid')) {
      return 'Global.Error.HexColorInvalid';
    } else if (value.hasOwnProperty('noPermission')) {
      return 'Global.Error.NoPermission';
    } else if (value.hasOwnProperty('permissionDenied')) {
      return 'Global.Error.PermissionDenied';
    } else if (value.hasOwnProperty('noDataDisplay')) {
      return 'Global.Error.NoDataDisplay';
    } else if (value.hasOwnProperty('decimal')) {
      return 'Global.Error.Decimal';
    } else if (Object.keys(value)[0]) {
      return `Error.${Object.keys(value)[0]}`;
    }
    return '';
  }
}
