import { Pipe, PipeTransform } from '@angular/core';
import { ITranslationSchema } from '../components/text-editor-slate/text-editor.interface';

@Pipe({
  name: 'getTranslationSourceByTargetItem',
})
export class GetTranslationSourceByTargetItemPipe implements PipeTransform {
  public transform(sourceSchema: ITranslationSchema[], targetItem: ITranslationSchema): ITranslationSchema {
    return (
      sourceSchema?.find((source) => source?.name === targetItem?.name) || {
        name: targetItem?.name,
        section: targetItem?.section,
      }
    );
  }
}
