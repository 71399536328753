import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlossaryDialogComponent } from './glossary-dialog.component';
import { GlossaryDialogItemModule } from '../glossary-dialog-item/glossary-dialog-item.module';
import { TranslateModule } from '@ngx-translate/core';
import { RoleVisibilityModule } from '../../directives';
import { DirectiveModule } from 'src/shared/directives/directive.module';

@NgModule({
  declarations: [GlossaryDialogComponent],
  imports: [DirectiveModule, CommonModule, TranslateModule, GlossaryDialogItemModule, RoleVisibilityModule],
  exports: [GlossaryDialogComponent],
})
export class GlossaryDialogModule {}
