import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkHref',
  standalone: true,
})
export class LinkHrefPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    if (value.startsWith('http')) {
      return value;
    }

    return `//${value}`;
  }
}
