import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterDropdownComponent } from './filter-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FilterDropdownComponent],
  imports: [CommonModule, TranslateModule, MatIconModule],
  exports: [FilterDropdownComponent],
})
export class FilterDropdownModule {}
