import { HttpClient } from '@angular/common/http';

import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Observable } from 'rxjs';
import { makeStateKey, StateKey, TransferState } from '@angular/core';

const PREFIX = 'i18n';
const SUFFIX = '.json';

export class TranslateLoaderService implements TranslateLoader {
  constructor(
    private prefix: string = PREFIX,
    private suffix: string = SUFFIX,
    private http: HttpClient,
    private transferState: TransferState,
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>(`transfer-translate-${lang}`);
    const data: any = this.transferState.get(key, null);
    if (data) {
      return new Observable((observer) => {
        observer.next(data);
        observer.complete();
      });
    }
    return new TranslateHttpLoader(this.http, this.prefix, this.suffix).getTranslation(lang);
  }
}
