import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, NgForOf, NgIf } from '@angular/common';
import { ProjectDraftHistoryItemComponent } from '../project-draft-history-item';
import { DialogRef } from '@angular/cdk/dialog';
import { IProjectDraftChangeLog } from '../../interfaces';
import { LocalSpinnerModule } from '../../modules';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-project-draft-history-dialog',
  templateUrl: './project-draft-history-dialog.component.html',
  styleUrls: ['./project-draft-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ProjectDraftHistoryItemComponent, NgIf, NgForOf, TranslateModule, LocalSpinnerModule],
  standalone: true,
})
export class ProjectDraftHistoryDialogComponent {
  public projectDraftChangeLogs: IProjectDraftChangeLog[];
  @Input() localSpinnerId: string;
  @Input() set dataSource(value: IProjectDraftChangeLog[]) {
    if (!value) {
      return;
    }
    this.projectDraftChangeLogs = value;
    this._changeDetectorRef.detectChanges();
  }
  @Output() exportChangeLog = new EventEmitter<IProjectDraftChangeLog>();
  @Output() loadMore = new EventEmitter<HTMLDivElement>();
  constructor(public dialogRef: DialogRef<ProjectDraftHistoryDialogComponent>, private _changeDetectorRef: ChangeDetectorRef) {}

  public onClose() {
    this.dialogRef.close();
  }

  public onScroll(element: HTMLDivElement) {
    this.loadMore.emit(element);
  }
}
