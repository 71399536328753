import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import firebase from 'firebase/compat';
import { BehaviorSubject, Observable, filter, from, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { FirebaseService } from 'src/shared/firebase/services/firebase.service';
import { IUser } from 'src/shared/interfaces/user.interface';
//import jwtDecode from 'jwt-decode';
import { ILoginForm } from '../../interfaces';
import { BrowserStorage, FIREBASE_USER_CREDENTIAL, STORAGE, USER_TOKEN } from '../../storages';
import { FavieTranslateService } from '../../translate/services/favie-translate.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private readonly apiUrl = 'auth';
  private readonly apiUserUrl = 'users';
  private _token: string;
  private _refreshToken: string;
  private _userDataFromToken: any;
  private _firebaseUserCredential: firebase.auth.UserCredential;
  public userProfile$ = new BehaviorSubject<IUser>(undefined);
  public logged$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(STORAGE) private readonly storage: BrowserStorage,
    private _angularFireStorage: AngularFireStorage,
    private _httpClient: HttpClient,
    private _firebaseService: FirebaseService,
    private _router: Router,
    private _favieTranslateService: FavieTranslateService,
  ) {
    //this.refreshUserToken();
    this.refreshFirebaseUserCredential();
  }

  public chanelLogout() {
    const url = `${this.apiUrl}/logout`;
    return this._httpClient.post(url, {});
  }

  public refreshUserToken() {
    const userToken = this.storage.getItem(USER_TOKEN);
    if (userToken) {
      const token = JSON.parse(userToken);
      this.setUserToken(token);
    }
  }

  public setUserToken(userToken: { token: string; refreshToken: string }) {
    this.storage.setItem(USER_TOKEN, JSON.stringify(userToken));
    this._token = userToken.token;
    this._refreshToken = userToken.refreshToken;
    //this._userDataFromToken = jwtDecode(this._token);
    this.setLogged(true);
  }

  public clearUserToken() {
    this.storage.removeItem(USER_TOKEN);
    this.storage.removeItem(FIREBASE_USER_CREDENTIAL);
    this._token = undefined;
    this._refreshToken = undefined;
    this._userDataFromToken = undefined;
    this._firebaseUserCredential = undefined;
    this.userProfile$.next(undefined);
    this.setLogged(false);
  }

  public setLogged(status: boolean) {
    this.logged$.next(status);
  }

  public getLogged() {
    return this.logged$.asObservable().pipe(debounceTime(200), distinctUntilChanged());
  }

  public getToken() {
    this.refreshUserToken();
    return this._token;
  }

  public setFirebaseUserCredential(firebaseUserCredential: firebase.auth.UserCredential) {
    this._firebaseUserCredential = firebaseUserCredential;
    this.storage.setItem(FIREBASE_USER_CREDENTIAL, JSON.stringify(this._firebaseUserCredential));
  }

  public refreshFirebaseUserCredential() {
    const firebaseUserCredential = this.storage.getItem(FIREBASE_USER_CREDENTIAL);
    if (firebaseUserCredential) {
      const token = JSON.parse(firebaseUserCredential);
      this.setFirebaseUserCredential(token);
    }
  }

  public signIn(formData: Partial<ILoginForm>) {
    return from(this._firebaseService.signInWithEmailAndPassword(formData)).pipe(
      filter((userCredential: firebase.auth.UserCredential) => !!userCredential),
      switchMap((userCredential) => this._setUserCredentialAndToken(userCredential)),
    );
  }

  public logout() {
    this._firebaseService.signOut();
    this.clearUserToken();
    this._router.navigate(['/auth']);
  }

  private async _setUserCredentialAndToken(userCredential: firebase.auth.UserCredential) {
    await this._setCustomUserClaims().toPromise();
    const userToken = await this._getUserToken(userCredential, true);
    this.setUserToken(userToken);
    this.setFirebaseUserCredential(userCredential);
  }

  private async _getUserToken(userCredential: firebase.auth.UserCredential, forceRefresh?: boolean) {
    const user = userCredential.user;
    const token = await user.getIdToken(forceRefresh);
    const refreshToken = user.refreshToken;
    return { token, refreshToken };
  }

  private _setCustomUserClaims() {
    return this._httpClient.post(`${this.apiUrl}/claims`, {});
  }

  public sendResetEmail(email: string) {
    return this._httpClient.post(`${this.apiUrl}/send-reset-password-email`, {
      email,
    });
  }

  public resetPassword(input: object) {
    return this._httpClient.post(`${this.apiUrl}/reset-password`, input);
  }

  public checkUserExisted(email: string) {
    return this._httpClient.get(`${this.apiUserUrl}/is-email-existed/${email}`);
  }

  public getProfile(): Observable<IUser> {
    if (this.userProfile$.value) {
      return of(this.userProfile$.value).pipe();
    } else {
      return this._httpClient.get<IUser>(`${this.apiUserUrl}/me`).pipe(
        tap((user: any) => this.setUserProfile(user)),
        map((user: any) => ({
          ...user,
        })),
      );
    }
  }

  public setUserProfile(userProfile: IUser) {
    this.userProfile$.next(userProfile);
  }

  public getUserProfile(): Observable<IUser> {
    return this.userProfile$.asObservable();
  }

  public getUserProfileValue(): IUser {
    return this.userProfile$.value;
  }

  public getUserPermissions() {
    return this._userDataFromToken?.permissions || this.userProfile$.value?.permissions;
  }
}
