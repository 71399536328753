import { Inject, Injectable } from '@angular/core';
import { TranslateService as NgxTranslateService, TranslateService } from '@ngx-translate/core';
import { CommonConstant } from 'src/shared/constants/common.constants';
import { PlatformBrowserService } from 'src/shared/services/platform-browser.service';
import { STORAGE } from 'src/shared/storages';

export interface ILang {
  code: string;
  name: string;
  culture: string;
}
// dead code
export const LANG_LIST: ILang[] = [
  { code: 'fr-FR_BE', name: 'Français', culture: 'fr' },
  { code: 'en-uk', name: 'English', culture: 'en' },
  { code: 'it', name: 'Italian', culture: 'it' },
  { code: 'ja', name: 'Japanese', culture: 'ja' },
  { code: 'zh-TW', name: 'Chinese', culture: 'zh-TW' },
  { code: 'zh', name: 'Simplified Chinese', culture: 'zh' },
  { code: 'ko', name: 'Korean', culture: 'ko' },
  { code: 'de', name: 'German', culture: 'de' },
];
@Injectable({ providedIn: 'root' })
export class FavieTranslateService {
  public static readonly LOCALE_STORAGE_KEY = 'LOCALE_STORAGE_KEY';
  constructor(
    @Inject(NgxTranslateService) private translate: NgxTranslateService,
    @Inject(STORAGE)
    protected readonly storage: Storage,
  ) {}

  public initLanguage(): Promise<any> {
    return new Promise((resolve: any) => {
      const defaultLanguage = this.storage.getItem(CommonConstant.LANGUAGE_TOKEN) || CommonConstant.DEFAULT_LANGUAGE;
      this.translate.setDefaultLang(defaultLanguage);
      this.translate.use(defaultLanguage).subscribe((lang) => {
        resolve();
      });
    });
  }

  public switchLanguage(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang).subscribe((lang) => {});
  }
}
