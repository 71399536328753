export enum IconName {
  ArrowRight = 'arrow-right.svg',
  Logo = 'logo',
  ChanelLogo = 'chanel-logo',
  MainChanelLogo = 'main-chanel-logo',
  Search = 'search-white.svg',
  Pen = 'pen.svg',
  RejectedPen = 'rejected-pen.svg',
  PenWhite = 'pen-white.svg',
  PenGrey = 'pen-grey.svg',
  Check = 'check.svg',
  CheckBlack = 'check-black.svg',
  CheckGrey = 'check-grey.svg',
  ToTop = 'to-top.svg',
  Cross = 'cross-white.svg',
  Review = 'review.svg',
  ReviewGrey = 'review-grey.svg',
  ReviewBlack = 'review-black.svg',
  Mail = 'mail.svg',
  Bin = 'bin.svg',
  Refresh = 'refresh.svg',
  Hand = 'hand.svg',
  ThumbUp = 'thumb-up.svg',
  Book = 'book',
  BubbleIcon1 = 'bubble-icon-1',
  ChevronDown = 'chevron-down',
  FilterChervonDown = 'filter-chevron-down',
  FilterActiveChevronDown = 'filter-active-chevron-down',
  Filter = 'filter',
  KeyNotes = 'key-notes',
  Plus2 = 'plus-2',
  Clock = 'clock',
  UserProfileChevronDown = 'user-profile-chevron-down',
  UserProfileActiveChevronDown = 'user-profile-active-chevron-down',
  Lock = 'lock',
  LogOut = 'log-out',
  Link = 'link.svg',
  Import = 'import',
  Export = 'export',
  ImportGrey = 'import-grey',
  ExportGrey = 'export-grey',
  GlossaryChervonDown = 'glossary-chervon-down',
  Back = 'back',
  Plus = 'plus',
  PlusWhite = 'plus-white.svg',
  NodataSearch = 'no-data-search.svg',
  SearchInput = 'search-input.svg',
  GuideBubbleEdit = 'guide-bubble-edit.svg',
  GuideBubbleCheck = 'guide-bubble-check.svg',
  TextBubbleCopy = 'text-bubble-copy.svg',
  FloatingBarPen = 'floating-bar-pen',
  FloatingBarRejectedPen = 'floating-bar-rejected-pen',
  FloatingBarCross = 'floating-bar-cross',
  FloatingBarCheck = 'floating-bar-check',
  FloatingBarDisabledCheck = 'floating-bar--disabled-check',
  FloatingBarDisabledPen = 'floating-bar-disabled-pen',
  FloatingBarReview = 'floating-bar-review',
  FloatingBarDisabledReview = 'floating-bar-disabled-review',
  DraftStatusDefault = 'draft-status-default',
  DraftStatusClose = 'draft-status-close',
  DraftStatusValidated = 'draft-status-validated',
  DraftStatusRejected = 'draft-status-rejected',
  DraftStatusWip = 'draft-status-wip',
  SectionMenu = 'section-menu',
  DownloadCSVArrowDown = 'download-csv-arrow-down',
  DownloadCSVArrowDownGrey = 'download-csv-arrow-down-grey',
  ChangeLog = 'change-log',
  Translation = 'translation',
  TranslationError = 'translation-error',
  TranslationSuccess = 'translation-success',
  TranslationGoogle = 'translation-google',
  TranslationGoogleError = 'translation-google-error',
  TranslationGoogleSuccess = 'translation-google-success',
  GEAR = 'gear',
  SHUTTLE = 'shuttle',
  PROTOTYPE = 'prototype',
  VOLUME_ON = 'volume-on',
  VOLUME_OFF = 'volume-off',
  PHONE_ROTATE = 'phone-rotate',
}
