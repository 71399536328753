import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  HostListener,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import { IEntityOption } from '@app/core';
import { IconName } from 'src/shared/enums';

@Component({
  selector: 'app-filter-dropdown',
  templateUrl: './filter-dropdown.component.html',
  styleUrls: ['./filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDropdownComponent {
  public readonly ICON_NAMES = IconName;
  public isClicked = false;
  @Input() options: IEntityOption[];
  @Input() defaultOption: IEntityOption;
  @Output() optionClicked = new EventEmitter();
  @ViewChild('dropdownEl') dropdownEl: ElementRef;
  @HostListener('document:click', ['$event'])
  public onClickOutSide(event) {
    if (!this.dropdownEl.nativeElement.contains(event.target)) {
      //click outside
      this.isClicked = false;
    } else {
      // click inside
      this.isClicked = true;
    }
  }
}
