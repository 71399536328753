export enum LanguageDetailFilterStatusEnum {
  ALL_KEYS = 'ALL_KEYS',
  SEARCH = 'SEARCH',
  NEW_KEYS = 'NEW_KEYS',
  MISSING_TRANSLATION = 'MISSING_TRANSLATION',
  ALL_SECTIONS = 'ALL_SECTIONS',
  NO_SECTION = 'NO_SECTION',
  SECTION = 'SECTION',
  AUDIO_ONLY = 'AUDIO_ONLY',
}

export enum LanguageDetailFilterTypeEnum {
  FILTER = 'FILTER',
  SEARCH = 'SEARCH',
  FILTER_SECTION = 'FILTER_SECTION',
}

export enum LanguageFixTranslationActionEnum {
  FULL_FIX = 'FULL_FIX',
  LITE_FIX = 'LITE_FIX',
}

export enum TranslationErrorSeverityEnum {
  ALERT = 'ALERT',
  ERROR = 'ERROR',
  INFO = 'INFO',
  REJECTED = 'REJECTED',
  WIP = 'WIP',
}
