<div class="container" [class.expanded]="dataSource?.isExpanded">
  <div class="header clickable hover:scale-100" (click)="dataSource['isExpanded'] = !dataSource?.isExpanded">
    <div class="title text--long-md--medium" [ngClass]="dataSource?.errorClass || ''">{{ dataSource?.name }}</div>
    <div class="action">
      <mat-icon class="chevron icon--sm d--flex align__items--center" [svgIcon]="ICON_NAMES.GlossaryChervonDown"></mat-icon>
    </div>
  </div>
  <div class="body" *ngIf="dataSource?.isExpanded">
    <div
      class="title text--long-md--medium"
      [ngClass]="dataSource?.errorClass || ''"
      [innerHTML]="dataSource?.glossaryCards[0]?.value | translate"
    ></div>
    <div class="content label-min-md--regular" [innerHTML]="dataSource?.description | translate"></div>
  </div>
</div>
