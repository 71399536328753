import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DialogRef } from '@angular/cdk/dialog';
import { Observable } from 'rxjs';
import { IHistoryLog } from './key-history-dialog-interface';
import { DraftService } from '@app/core';
import { CustomDateFormatEnum } from '../../enums';

@Component({
  selector: 'app-key-history-dialog',
  templateUrl: './key-history-dialog.component.html',
  styleUrls: ['./key-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyHistoryDialogComponent implements OnInit {
  public readonly CustomDateFormatEnum = CustomDateFormatEnum;

  @Input() public draftId: string;
  @Input() public editedItemId: string;
  public historyLog$: Observable<IHistoryLog[]>;

  constructor(public dialogRef: DialogRef<KeyHistoryDialogComponent>, private draftService: DraftService) {}

  ngOnInit() {
    if (!this.draftId || !this.editedItemId) {
      return;
    }

    this.historyLog$ = this.draftService.historyLog(this.draftId, this.editedItemId);
  }

  onClose() {
    this.dialogRef.close();
  }
}
