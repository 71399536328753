import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { IEntityOption } from '@app/core';
import { DefaultSectionOption, NoSectionOption } from 'src/app/modules/language-detail/language-detail.const';
import { IconName } from 'src/shared/enums';

@Component({
  selector: 'app-menu-sections',
  templateUrl: './menu-sections.component.html',
  styleUrls: ['./menu-sections.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuSectionsComponent {
  public readonly ICON_NAMES = IconName;
  public readonly ALL_SECTIONS_OPTION = DefaultSectionOption;
  public readonly NO_SECTIONS_OPTION = NoSectionOption;

  public selectedSectionIdx = -1;
  public isClicked = false;
  @Input() options: IEntityOption[];
  @Input() defaultOption: IEntityOption;
  @Output() optionClicked = new EventEmitter();
  @ViewChild('dropdownEl') dropdownEl: ElementRef;
  @HostListener('document:click', ['$event'])
  public onClickOutSide(event) {
    this.isClicked = this.dropdownEl.nativeElement.contains(event.target);
  }
  onOptionClicked(option: IEntityOption, index: number) {
    this.selectedSectionIdx = index;
    this.optionClicked.emit(option);
  }
}
