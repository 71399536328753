<ng-container *ngIf="!isSpecialIcon; else specialIcon">
  <svg [class.normal-icon]="iconHover" [style.width]="'100%'" [style.height]="'100%'">
    <use [attr.href]="'assets/stack/icon.svg?v=' + timestamp + '#' + icon"></use>
  </svg>

  <svg *ngIf="iconHover" [class.on-hover-icon]="iconHover" [style.width]="'100%'" [style.height]="'100%'">
    <use [attr.href]="'assets/stack/icon.svg/v=' + timestamp + '#' + iconHover"></use>
  </svg>
</ng-container>

<ng-template #specialIcon>
  <img
    [class.normal-icon]="iconHover"
    [src]="'assets/icons/special-svg/' + icon + '.svg'"
    [style.width.px]="hostWidth"
    [style.height.px]="hostHeight"
    [alt]="alt"
  />

  <img
    *ngIf="iconHover"
    [class.on-hover-icon]="iconHover"
    src="assets/svg-icons/{{ iconHover }}"
    [style.width.px]="hostWidth"
    [style.height.px]="hostHeight"
    [alt]="alt"
  />
</ng-template>
