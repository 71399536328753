export enum DialogModeEnum {
  IMPORT_DRAFT_ERROR = 'IMPORT_DRAFT_ERROR',
  IMPORT_DRAFT_INFO = 'IMPORT_DRAFT_INFO',
  UPDATE_DRAFT_ERROR = 'UPDATE_DRAFT_ERROR',
  UPDATE_DRAFT_INFO = 'UPDATE_DRAFT_INFO',
  IMPORT_SOURCE = 'IMPORT_SOURCE',
  PUBLISH_TRANSLATION = 'PUBLISH_TRANSLATION',
  CANCEL_LITE_FIX = 'CANCEL_LITE_FIX',
  UPDATE_DRAFT_KEY_STATUS = 'UPDATE_DRAFT_KEY_STATUS',
  UPDATE_DRAFT_KEY_STATUS_DO_NOTHING = 'UPDATE_DRAFT_KEY_STATUS_DO_NOTHING',
  IMPORT_SOURCE_HAVE_ACTIVATED_DRAFT = 'IMPORT_SOURCE_HAVE_ACTIVATED_DRAFT',
  IMPORT_DRAFT_BLOCK_FLOW_DO_NOTHING = 'IMPORT_DRAFT_BLOCK_FLOW_DO_NOTHING',
  IMPORT_DRAFT_BLOCK_FLOW_HAS_FIXES = 'IMPORT_DRAFT_BLOCK_FLOW_HAS_FIXES',

  CANCEL = 'CANCEL',
}
