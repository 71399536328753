import WaveSurfer from 'wavesurfer.js';

export function createWaveSurfer(
  container: HTMLElement,
  url: string | null = null,
  progressColor: string = 'rgb(247, 183, 80)'
): WaveSurfer {
  return WaveSurfer.create({
    container,
    waveColor: 'rgb(197, 184, 157)',
    progressColor,
    barWidth: 8,
    barRadius: 10,
    barGap: 2,
    url,
    height: 40,
    cursorColor: 'transparent'
  })
}

export function formatProgress(time: number): string {
  // time will be in milliseconds, convert it to mm:ss format
  return [
    Math.floor((time % 3600000) / 60000), // minutes
    Math.floor((time % 60000) / 1000), // seconds
  ]
    .map((v) => (v < 10 ? '0' + v : v))
    .join(':');
}
