import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IHistoryLog } from '../key-history-dialog';
import { DraftKeyStatusEnum } from '../../enums';
import { HistoryLogActionEnum } from '../../enums';
import { CustomDateFormatEnum } from '../../enums';

@Component({
  selector: 'app-key-history-item',
  templateUrl: './key-history-item.component.html',
  styleUrls: ['./key-history-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyHistoryItemComponent {
  public isExpanded = false;
  public readonly HistoryLogActionEnum = HistoryLogActionEnum;
  public readonly DraftKeyStatusEnum = DraftKeyStatusEnum;
  public readonly CustomDateFormatEnum = CustomDateFormatEnum;
  @Input() historyLog: IHistoryLog;

  public expanded() {
    this.isExpanded = !this.isExpanded;
  }
}
