<div class="header">
  <div class="title cta-xl-semi-bold">{{ 'Glossary.Dashboard.Title.Label' | translate }}</div>
  <div class="action">
    <div
      class="setting clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default"
      (click)="onGoToSetting()"
      [appRoleVisibility]="['ADMIN']"
    >
      <img src="assets/icons/setting-white.svg" alt="setting" />
    </div>
    <div class="close clickable clickable__wrapper btn__icon-rectangle btn__icon-rectangle--default" (click)="onClose()">
      <img src="assets/icons/close-white.svg" alt="close" />
    </div>
  </div>
</div>
<div class="body">
  <div class="title label-maj-xs--medium subtitle">
    {{ 'Glossary.Dialog.ItemsCount.Label' | translate : { count: glossaryData?.length || 0 } | uppercase }}
  </div>
  <div class="glossary-container p__t--2">
    <app-glossary-dialog-item [dataSource]="item" *ngFor="let item of glossaryData"></app-glossary-dialog-item>
  </div>
</div>
