import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGE_ASSETS } from '../constants/language-assets.constant';

@Pipe({
  name: 'flagByCode',
})
export class FlagByCodePipe implements PipeTransform {
  transform(code: string): string {
    const long = (code || '').toLowerCase()
    const short = long.match(/^\w+/g)?.[0] || ''
    const tmp = LANGUAGE_ASSETS[long]?.flag
      || LANGUAGE_ASSETS[short]?.flag
      || LANGUAGE_ASSETS['default']?.flag
    return tmp
  }
}
