import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class EnvironmentInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const { apiUrl, version } = environment;
    const origUrl = req.url
    const nextUrl = origUrl.includes('assets') && !origUrl.includes('drafts')
      ? origUrl
      : `${apiUrl}/${version}/${origUrl}`;
    return next.handle(req.clone({ url: nextUrl }));
  }
}
