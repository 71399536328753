import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { MessagingService } from './services/messaging.service';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { FirebaseService } from './services';

@NgModule({
  imports: [MatSnackBarModule, TranslateModule, RouterModule, AngularFireMessagingModule, AngularFireFunctionsModule],
  providers: [FirebaseService, MessagingService],
})
export class FirebaseModule {}
