import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { INote } from '../../text-editor-slate/text-editor.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-text-area-wrapper',
  templateUrl: './text-area-wrapper.component.html',
  styleUrls: ['./text-area-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextAreaWrapperComponent implements AfterViewInit {
  public readonly maximumKeyNoteValue = 1000;
  @Input() note: INote;
  @Input() set isAddNewKeyNote(value) {
    if (value) {
      requestAnimationFrame(() => this.element?.nativeElement.focus());
    }

    this.calculateTextAreaHeight();
  }

  @Output() isFocus = new EventEmitter<boolean>();
  @Output() isBlur = new EventEmitter<void>();
  @Output() keyNoteChanged = new EventEmitter<string>();

  @ViewChild('inputKeyNote') element: ElementRef;
  @ViewChild('autoSize') autoSize: CdkTextareaAutosize;
  constructor(private _changeDetectorRef: ChangeDetectorRef, private _translateService: TranslateService) {}

  ngAfterViewInit() {
    this.calculateTextAreaHeight();
  }

  public onKeyNoteChange(value: string) {
    this.keyNoteChanged.emit(value);
  }

  public calculateTextAreaHeight() {
    this.autoSize?.resizeToFitContent(true);
    if (!this.note?.value && !!this.element?.nativeElement) {
      this.element.nativeElement.placeholder = this._translateService.instant('Project.Detail.KeyNote.TextInput.Placeholder');
    }

    this._changeDetectorRef.detectChanges();
  }
}
