import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppRouteEnum } from '../enums';
import { UserService } from '../services';
import { SSOAuthService } from '../services/auth/sso-auth.service';

@Injectable({ providedIn: 'root' })
export class UserGuard {
  constructor(
    private readonly _router: Router,
    private readonly _ssoAuthService: SSOAuthService,
    private readonly _userService: UserService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this._ssoAuthService.getToken()) {
      this._router.navigate([AppRouteEnum.Welcome]);
      return of(false);
    }

    return this._userService.getProfile().pipe(
      map((user) => {
        if (user) {
          return true;
        }

        this._router.navigate([AppRouteEnum.Welcome]);
        return false;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 403) {
          this._router.navigate([AppRouteEnum.Welcome, AppRouteEnum.AccountDeactivated]);
          return of(false);
        }
        return of(null);
      }),
    );
  }
}
