import { Injectable } from '@angular/core';
import { catchError, mergeMap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subscribable } from 'src/shared/components/base-component/subscribable';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';

@Injectable()
export class MessagingService extends Subscribable {
  newMessage$ = new BehaviorSubject(null);
  private readonly END_POINT = `${environment.apiUrl}/v1/subscription`;
  constructor(private readonly http: HttpClient, private angularFireMessaging: AngularFireMessaging) {
    super();
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   */
  public requestPermission() {
    this.subscribe(
      // to avoid type error from node module mismatch type check
      (this.angularFireMessaging.requestPermission as any).pipe(
        mergeMap(() => this.angularFireMessaging.tokenChanges),
        mergeMap((token: string) => this.subscribeToEntityTopic(token)),
        catchError((error) => {
          throw error;
        }),
      ),
      () => {},
    );
  }
  /**
   * hook method when new notification received in foreground
   */
  public receiveMessage() {
    this.subscribe(
      // to avoid type error from node module mismatch type check
      (this.angularFireMessaging.messages as any).pipe(
        catchError((error) => {
          throw error;
        }),
      ),
      (message: any) => {
        console.log('Message received:', message);
        message.onBackgroundMessage = message.onBackgroundMessage?.bind(message);
        this.newMessage$.next(message);
      },
    );
  }

  private subscribeToEntityTopic(token: string) {
    return this.http.post(`${this.END_POINT}/entity`, { token });
  }

  public triggerNotify() {
    return this.newMessage$.next(null);
  }

  public triggerMessage(data?) {
    const mockupData = {
      data: {
        data: JSON.stringify({
          message: data?.message,
          conversationId: data?.conversationId,
          notAppend: data?.notAppend || false,
        }),
      },
    };
    return this.newMessage$.next(mockupData);
  }

  public getObsNewMessage() {
    return this.newMessage$.asObservable();
  }

  onUnsubscribe(): void {
    this.newMessage$.next(undefined);
  }
}
