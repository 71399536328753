import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserProfileComponent } from './user-profile.component';
import { TranslateModule } from '@ngx-translate/core';
import { LanguageToggleModule } from '../language-toggle/language-toggle.module';
import { MatIcon, MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [UserProfileComponent],
  imports: [CommonModule, TranslateModule, MatIconModule, LanguageToggleModule],
  exports: [UserProfileComponent],
})
export class UserProfileModule {}
