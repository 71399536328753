interface ILanguageConfigMap {
  [name: string]: {
    DeepL: boolean,
    ElevenLabs: boolean,
  },
}

export const LANGUAGE_CONFIG_MAP: ILanguageConfigMap = {
  hu: {
    DeepL: true,
    ElevenLabs: true,
  },
  no: {
    DeepL: true,
    ElevenLabs: true,
  },
  sl: {
    DeepL: true,
    ElevenLabs: false,
  },
  ar: {
    DeepL: false,
    ElevenLabs: true,
  },
  hr: {
    DeepL: false,
    ElevenLabs: true,
  },
  ms: {
    DeepL: false,
    ElevenLabs: true,
  },
  sr: {
    DeepL: false,
    ElevenLabs: false,
  },
  th: {
    DeepL: false,
    ElevenLabs: false,
  },
  'zh-TW': {
    DeepL: false,
    ElevenLabs: true,
  },
  vi: {
    DeepL: true,
    ElevenLabs: true,
  },
  'vi-VN': {
    DeepL: false,
    ElevenLabs: true,
  },
}
