import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KeyHistoryItemComponent } from './key-history-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../pipes';

@NgModule({
  declarations: [KeyHistoryItemComponent],
  imports: [CommonModule, TranslateModule, PipesModule],
  exports: [KeyHistoryItemComponent],
})
export class KeyHistoryItemModule {}
